import { createApp } from 'vue';
import Application from './Application.vue';

import { IonicVue, isPlatform } from '@ionic/vue';

import { key, loadState, store } from "./store";
import { getRouter } from './router';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/core.scss';

import { ACTIONS } from "@/store/actions";
import { createStorage } from "@/store/storage";
import { Analytics, Auth } from "@/firebase/credentials";
import { MUTATIONS } from "@/store/mutations";
import { User } from 'firebase/auth';
import { pluralizeMixin } from "@/mixins/pluralizeMixin";
import { platformMixin } from "@/mixins/platformMixin";
import { logEvent } from "firebase/analytics";
// import {addLinkedIn} from "@/auth/linkedin";
import { BrowserTracing } from "@sentry/tracing";
import VCalendar from 'v-calendar';
import { i18n } from "./i18n";
import { getCurrentProfile } from './common/userService';
import { version } from "../package.json";
import Chat from 'vue3-beautiful-chat'

function initApp() {
    const router = getRouter();
    const app = createApp(Application)
        .use(VCalendar, {})
        .use(IonicVue, { mode: 'md' })
        .use(router)
        .use(store, key)
        .use(i18n)
        .use(Chat)
        .mixin(platformMixin)
        .mixin(pluralizeMixin);

    router.isReady().then(async () => {
        await store.dispatch(ACTIONS.OBSERVE_AUTH_STATE_CHANGED);
        app.mount('#app');
        if (isPlatform("capacitor")) {
            const tag = document.createElement("script");
            tag.src = "https://www.youtube.com/iframe_api";
            const firstScriptTag: any = document.getElementsByTagName("script")[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }
        if (!!process.env.VUE_APP_SENTRY_DSN) {
            const Sentry = await import("@sentry/vue");
            Sentry.init({
                app,
                dsn: process.env.VUE_APP_SENTRY_DSN,
                release: version,
                integrations: [
                    new BrowserTracing({
                        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                        tracingOrigins: ["app.mastermatch.online", /^\//],
                    }),
                ],
                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 0.8,
                logErrors: true
            });
            console.log("Sentry ON");
        }
    });
}

const unsubscribe = Auth.onIdTokenChanged(async (user: User | null) => {
    await createStorage();
    await loadState();
    if (user) {
        await store.commit(MUTATIONS.SET_USER, user);
        await store.dispatch(ACTIONS.OBSERVE_PROFILE_CHANGES);
        await store.dispatch(ACTIONS.RETRIEVE_SUBSCRIPTION);
        const profile = await getCurrentProfile(Auth.currentUser?.uid);
        await store.commit(MUTATIONS.SET_USER_PROFILE, profile);
    }
    await store.dispatch(ACTIONS.SET_LOCALE);
    initApp();
    // Call the element loader after the platform has been bootstrapped
    defineCustomElements(window);
    unsubscribe();
});

window.addEventListener('appinstalled', (evt) => {
    logEvent(Analytics, 'pwa_app_installed');
});