// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import { initializeApp } from 'firebase/app';
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { getMessaging, onMessage } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import 'firebase/firestore';
import { toast } from "@/common/alerts";
import { store } from "@/store";
import { MUTATIONS } from "@/store/mutations";

const { NODE_ENV } = process.env;
const isDevEnv = NODE_ENV === 'development';
const isTestEnv = NODE_ENV === 'test';
const firebaseConfig = isDevEnv || isTestEnv ? require('/firebaseTestConfig.json') : require('/firebaseProdConfig.json');

// Initialize Firebase
export const FirebaseApp = initializeApp(firebaseConfig);
export const Auth = getAuth(FirebaseApp);
export const Firestore = getFirestore(FirebaseApp);
export const Storage = getStorage(FirebaseApp);
export const Functions = getFunctions(FirebaseApp);
export const Messaging = getMessaging(FirebaseApp);
export const Analytics = getAnalytics(FirebaseApp);

onMessage(Messaging, (payload) => {
    console.log("Received push message", payload);
    if (payload.data?.label) {
        store.commit(MUTATIONS.SET_PUSH_NOTIFICATION, payload.data.label);
    } else {
        toast({
            header: payload.notification?.title,
            message: payload.notification?.body,
        }, true);
    }
});

if (isDevEnv) {
    console.log("Starting in development mode, connecting to Firebase emulators");
    connectAuthEmulator(Auth, "http://localhost:9099");
    connectFirestoreEmulator(Firestore, "localhost", 8081);
    connectFunctionsEmulator(Functions, "localhost", 5001);
    connectStorageEmulator(getStorage(), "localhost", 9199);
}