import {
    collection,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    runTransaction,
    setDoc,
    updateDoc,
    where
} from "firebase/firestore";
import {Firestore, Auth} from "@/firebase/credentials";
import {
    Chat,
    DailyMeetingToken,
    Inquiry,
    InquiryProposal,
    Meeting,
    MeetingInfo,
    MeetingStatus
} from "mastermatch-shared";
import {getProfile} from "@/common/userService";
import {getInquiry, updateProposalStatus} from "@/common/matchingService";
import groupBy from "lodash-es/groupBy";
import moment from "moment";
import {toast} from "@/common/alerts";
import {i18n} from "@/i18n";
import {QueryConstraint} from "@firebase/firestore";

const meetingsCollectionRef = collection(Firestore, "meetings");
const chatRef = (meetingId: string) => doc(meetingsCollectionRef, meetingId, Chat.collectionName, meetingId);


export async function getMeeting(id: string) {
    const snap = await getDoc(doc(meetingsCollectionRef, id));
    return snap.exists() ? Meeting.fromPlainObject(snap.data(), snap.id) : null;
}

export function observeMeetingChanges(id: string, listener: (meeting: Meeting | null) => void) {
    return onSnapshot(doc(meetingsCollectionRef, id), snap => {
        listener(snap.exists() ? Meeting.fromPlainObject(snap.data(), snap.id) : null);
    });
}

export enum MasterMeetingTypes {
    INCOMING,
    OUTGOING,
    INCOMING_OUTGOING
}

async function getMasterMeetingsByType(startDate: Date | undefined, statuses: MeetingStatus[], meetingTypes: MasterMeetingTypes, profileId: string, endDate: Date | undefined) {
    startDate = startDate || new Date();
    const queryConstraints = [] as QueryConstraint[];
    if (statuses.length) {
        queryConstraints.push(where("status", "in", statuses));
    }
    switch (meetingTypes) {
        case MasterMeetingTypes.INCOMING:
            queryConstraints.push(where("masterId", "==", profileId));
            break;
        case MasterMeetingTypes.OUTGOING:
            queryConstraints.push(where("userId", "==", profileId));
            break;
    }
    if (startDate) {
        queryConstraints.push(where("datetime", ">=", startDate));
    }
    if (endDate) {
        queryConstraints.push(where("datetime", "<=", endDate));
    }
    const querySnap = await getDocs(query(meetingsCollectionRef, ...queryConstraints));
    if (querySnap.size > 0) {
        return querySnap.docs.map((d) => Meeting.fromPlainObject(d.data(), d.id));
    }
    return [];
}

export async function getMasterMeetings(profileId: string, startDate?: Date, endDate?: Date, meetingTypes: MasterMeetingTypes = MasterMeetingTypes.INCOMING, statuses = [MeetingStatus.SCHEDULED, MeetingStatus.COMPLETED]) {
    if (meetingTypes == MasterMeetingTypes.INCOMING_OUTGOING) {
        const [outgoing, incoming] = await Promise.all([
            getMasterMeetingsByType(startDate, statuses, MasterMeetingTypes.OUTGOING, profileId, endDate),
            getMasterMeetingsByType(startDate, statuses, MasterMeetingTypes.OUTGOING, profileId, endDate)
        ]);
        return outgoing.concat(incoming);
    }
    return await getMasterMeetingsByType(startDate, statuses, meetingTypes, profileId, endDate);
}

export async function getMasterCalendar(masterId: string, startDate?: Date, endDate?: Date, meetingTypes: MasterMeetingTypes = MasterMeetingTypes.INCOMING, statuses = [MeetingStatus.SCHEDULED, MeetingStatus.COMPLETED]): Promise<Map<string, Meeting[]>> {
    const masterMeetings = await getMasterMeetings(masterId, startDate, endDate, meetingTypes, statuses);
    const meetingsByDate = groupBy(masterMeetings, (m: Meeting) => m.datetime.toDateString());
    return new Map(Object.entries(meetingsByDate));
}

async function validateNoOtherMeetings(userId: string, startDate: Date, endDate: Date) {
    const endDateExclusive = moment(endDate).add(-1, "second").toDate();
    const meetings = await getMasterMeetings(userId, startDate, endDateExclusive, MasterMeetingTypes.INCOMING_OUTGOING, [MeetingStatus.SCHEDULED]);
    return !meetings.length;
}


export async function saveMeeting(inqProposal: InquiryProposal, utcDate: Date, chat?: Chat) {
    const userProfile = await getProfile(inqProposal.proposal.inquiryUser.profileId as string);
    const masterProfile = inqProposal.masterProfile;
    const {duration} = masterProfile.scheduleSettings || {duration: 1};
    const hasNoOtherMeetings = await validateNoOtherMeetings(userProfile.id as string, utcDate, moment(utcDate).add(duration, "h").toDate());
    if (!hasNoOtherMeetings) {
        await toast({
            header: i18n.global.t('meetings.validation.otherMeetingsHeader'),
            message: i18n.global.t('meetings.validation.otherMeetingsMsg'),
            color: "danger"
        }, true);
        return;
    }
    const proposal = inqProposal.proposal;
    const inquiry = await getInquiry(proposal.inquiryId) as Inquiry;
    const meetingId = inqProposal.proposal.meeting?.id || (await doc(meetingsCollectionRef)).id;
    if (chat) {
        if (!chat.topic.includes(inquiry.titleValue)) {
            chat.topic += ' ' + inquiry.titleValue;
        }
        await saveChat(meetingId, chat);
    }
    const meeting = new Meeting(
        proposal.masterId,
        masterProfile.professionalInfo.fullName || masterProfile.email as string,
        masterProfile.email as string,
        masterProfile.imageUrl,
        userProfile.id as string,
        userProfile.professionalInfo.fullName || userProfile.email as string,
        userProfile.email as string,
        userProfile.imageUrl,
        proposal.inquiryId,
        proposal.id as string,
        inquiry.titleValue,
        utcDate,
        duration as number,
        Auth.currentUser?.uid || ''
    );
    if (!proposal.meeting?.id) {
        await setDoc(doc(meetingsCollectionRef, meetingId), meeting.toPlainObject());
        return new MeetingInfo(meeting.getTimeRange(), meetingId, MeetingStatus.SCHEDULED);
    }
    await updateDoc(doc(meetingsCollectionRef, proposal.meeting.id), meeting.toPlainObject());
    const info = new MeetingInfo(meeting.getTimeRange(), proposal.meeting.id, MeetingStatus.SCHEDULED);
    await updateProposalStatus(proposal.inquiryId, proposal.masterId, {meeting: info});
    return info;
}

export async function cancelMeeting(meetingId: string, cancelReason?: string) {
    try {
        const mDocRef = doc(meetingsCollectionRef, meetingId);
        await runTransaction(Firestore, async (transaction) => {
            const sfDoc = await transaction.get(mDocRef);
            if (!sfDoc.exists()) {
                throw "Meeting does not exist!";
            }
            transaction.update(mDocRef, Meeting.asCancelledUpdate(cancelReason));
        });
    } catch (e) {
        console.log("Transaction failed: ", e);
        throw "Error updating the meeting";
    }
}

export async function getSecuredMeetingUrl(meeting: Meeting, profileId: string): Promise<string | null> {
    const tokenDoc = await getDoc(doc(meetingsCollectionRef, meeting.id as string, DailyMeetingToken.collectionName, profileId));
    if (tokenDoc.exists()) {
        const token = DailyMeetingToken.fromPlainObject(tokenDoc.data());
        return token.getSecureMeetingUrl(meeting.providerMeetingDetails?.url as string);
    }
    return null;
}

export async function getChatById(chatId: string) {
    const snap = await getDoc(chatRef(chatId));
    if (snap.exists()) {
        return Chat.fromPlainObject(snap.data(), snap.id);
    }
}

export function observeChatChanges(chatId, listener: (chat: Chat) => void) {
    return onSnapshot(chatRef(chatId), (chatSnap) => {
        const chat = Chat.fromPlainObject(chatSnap.data(), chatSnap.id);
        listener(chat);
    });
}

export async function saveChat(chatId: string, chat: Chat) {
    const snap = await getDoc(chatRef(chatId));
    if (snap.exists()) {
        await updateDoc(snap.ref, chat.toPlainObject());
    } else {
        await setDoc(snap.ref, chat.toPlainObject());
    }
}