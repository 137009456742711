import {createStore, Store, useStore as baseUseStore} from 'vuex';
import mutations from "@/store/mutations";
import {actions, getters} from "@/store/actions";
import {InjectionKey} from "vue";
import {loadFcmToken, loadSettings, loadUser} from "@/store/storage";
import moment from "moment";
import {Subscription} from "chargebee-typescript/lib/resources";
import {LineItem} from "chargebee-typescript/lib/resources/invoice";
import {IdTokenResult, User, UserInfo} from 'firebase/auth'
import {
    BusinessEvent,
    InquiryNotification,
    Mastership,
    NotificationMessageCode,
    PersonalProfile,
    Tenant
} from "mastermatch-shared";

export class AuthToken {
    constructor(
        public token: string | null = null,
        public expirationTime: string | null = null,
        public authTime: string | null = null,
        public issuedAtTime: string | null = null,
        public signInProvider: string | null = null
    ) {
    }

    isValid(): boolean {
        return !!this.expirationTime && new Date(this.expirationTime) > new Date();
    }
}

export class AuthUserInfo {
    constructor(public uid: string | null = null,
                public providerId: string | null = null,
                public email: string | null = null,
                public displayName: string | null = null,
                public photoUrl: string | null = null,
                public createdAt: string | null = null,
    ) {
    }

    static fromFirebaseUserInfo(userInfo: UserInfo | null) {
        return userInfo ? new AuthUserInfo(
            userInfo.uid,
            userInfo.providerId,
            userInfo.email,
            userInfo.displayName,
            userInfo.photoURL,
            null
        ) : new AuthUserInfo();
    }

    static fromFirebaseUser(user: User | null) {
        return user ? new AuthUserInfo(
            user.uid,
            user.providerId,
            user.email,
            user.displayName,
            user.photoURL,
            user.metadata.creationTime
        ) : new AuthUserInfo();
    }
}

export class AuthUser {
    public userInfo: AuthUserInfo;
    public providerData: AuthUserInfo[];
    public tokenInfo: AuthToken;
    public tenant: string | null

    constructor(userInfo: User | null = null, token: IdTokenResult | null = null) {
        this.userInfo = AuthUserInfo.fromFirebaseUser(userInfo);
        this.tokenInfo = token ? new AuthToken(
            token.token,
            token.expirationTime,
            token.authTime,
            token.issuedAtTime,
            token.signInProvider,
        ) : new AuthToken();
        this.providerData = userInfo?.providerData.map((userInfo) => AuthUserInfo.fromFirebaseUserInfo(userInfo)) || [];
        this.tenant = token && token.claims.tenant as string;
    }

    public isAuthenticated(): boolean {
        return !!this.tokenInfo && this.tokenInfo.isValid();
    }

    toPlainObject() {
        return {
            userInfo: Object.assign({}, this.userInfo),
            tokenInfo: Object.assign({}, this.tokenInfo),
            providerData: this.providerData.map((data) => Object.assign({}, data)),
            tenant: this.tenant,
        };
    }

    public isLinkedWithProvider(providerId: string) {
        return this.providerData.some((authInfo) => authInfo.providerId === providerId);
    }

    public unlinkProvider(providerId: string) {
        this.providerData = this.providerData.filter((authInfo) => authInfo.providerId !== providerId)
    }

    public isEmpty() {
        return !this.userInfo.email;
    }
}

export class FcmToken {
    private static EXPIRY_IN_WEEKS = 8;

    constructor(
        public token: string | null = null,
        public userId: string | null = null,
        public timestamp = new Date()) {
    }

    public toPlainObject() {
        return Object.assign({}, this);
    }

    public isValidForUser(profileId: string) {
        if (!this.token || this.userId !== profileId) {
            return false;
        }
        const start = moment(this.timestamp);
        const now = moment(new Date());
        return moment.duration(now.diff(start)).asWeeks() < FcmToken.EXPIRY_IN_WEEKS;
    }

    public isExpiringSoon() {
        const start = moment(this.timestamp);
        const now = moment(new Date());
        const diff = moment.duration(now.diff(start)).asWeeks()
        return diff > FcmToken.EXPIRY_IN_WEEKS - 1;
    }
}

export interface ProductItem extends LineItem {
    linkedInvoice: {
        isPaid: boolean;
        id: string;
        status: string;
        dueDate: number;
    };
}

export type FeatureFlags = {
    subscriptions: boolean,
    ua: boolean
}

export type CurrentUserSettings = {
    pwaAcknowledged?: Date;
    splashShown?: boolean;
    languageCode?: string;
}

export type State = {
    authUser: AuthUser;
    userProfile: PersonalProfile;
    mastership?: Mastership;
    inquiryNotifications: InquiryNotification[];
    businessEvents: BusinessEvent[];
    subscriptionInfo: { subscription: Subscription | null; lineItems: ProductItem[] };
    fcmToken?: FcmToken;
    settings: CurrentUserSettings;
    referralCode?: string,
    isOrganization?: boolean,
    pushNotification?: NotificationMessageCode,
    openModal?: string,
    features: FeatureFlags
};

const state: State = {
    authUser: new AuthUser(),
    userProfile: new PersonalProfile(),
    mastership: new Mastership(),
    inquiryNotifications: new Array<InquiryNotification>(),
    businessEvents: new Array<BusinessEvent>(),
    subscriptionInfo: {subscription: null, lineItems: []},
    settings: {},
    features: {subscriptions: true, ua: false},
};

export async function loadState() {
    state.authUser = await loadUser();
    state.fcmToken = await loadFcmToken();
    state.settings = await loadSettings();
    state.features.ua = window.location.pathname.includes('/ua/') || state.authUser.tenant == Tenant.UA;
}

export const key: InjectionKey<Store<State>> = Symbol();
export const store = createStore<State>({state, getters, mutations, actions});

// our own useStore function for types
export function useStore() {
    return baseUseStore(key);
}
