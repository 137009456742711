import {store} from "@/store";

const API_URL = process.env.VUE_APP_API_BASE_URL;

const HEADERS = {
    'Content-Type': 'application/json',
};

function createHeaders() {
    return {
        'Authorization': 'Bearer ' + store.getters.authUser.tokenInfo.token,
        ...HEADERS
    };
}

export async function readJson(resp: Response) {
    const body = await resp.json();
    if (!resp.ok) {
        console.error(`Error response: ${resp.statusText}`, body);
        throw Error("Request error. " + body.message);
    }
    return body;
}

export async function postJson(path: string, body: any) {
    const response = await fetch(`${API_URL}/${path}`,
        {
            method: "POST",
            mode: 'cors',
            headers: createHeaders(),
            body: JSON.stringify(body)
        }
    );
    return await readJson(response);
}

export async function getJson(path: string, params: URLSearchParams | null = null) {
    let url = `${API_URL}/${path}`;
    if (params) {
        url += '?' + params.toString();
    }
    const response = await fetch(url,
        {
            headers: createHeaders()
        });
    return await readJson(response);
}