import {Auth, Firestore, Storage} from "@/firebase/credentials";
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    DocumentData,
    getDoc,
    getDocs,
    increment,
    onSnapshot,
    orderBy,
    query,
    Query,
    QuerySnapshot,
    setDoc,
    updateDoc,
    where
} from "firebase/firestore";
import {
    BusinessEvent,
    EventAdmissionType,
    EventFilters,
    EventSetting,
    PersonalProfile,
    TypeOfEvent
} from "mastermatch-shared";
import {store} from "@/store";
import {deleteObject, getBlob, getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {decode} from "base64-arraybuffer";
import {Photo} from "@capacitor/camera";
import {getJson} from "@/common/apiRestTemplate";

const eventsColRef = collection(Firestore, BusinessEvent.collectionName);
const eventsFiltersDoc = (profileId: string) => doc(Firestore, PersonalProfile.collectionName, profileId, EventFilters.collectionName, profileId);
const eventsStatsDoc = (profileId: string, eventId: string) => doc(Firestore, PersonalProfile.collectionName, profileId, 'event_stats', eventId);
const eventsStatsColRef = (profileId: string) => collection(Firestore, PersonalProfile.collectionName, profileId, 'event_stats');

export async function searchEventKeywords(term?: string, language?: string, limit?: number) {
    const params = new URLSearchParams();
    if (term === 'null') {
        return [];
    }
    if (term) {
        params.append('term', term);
    }
    if (language) {
        params.append('language', language);
    }
    if (limit) {
        params.append('limit', limit.toString());
    }
    return await getJson('search-event-keywords', params);
}

export async function createEvent(event: BusinessEvent) {
    const currentUser = store.state.userProfile;
    event.creatorId = currentUser.id as string;
    event.creatorImageUrl = currentUser.imageUrl;
    event.creatorName = currentUser.professionalInfo.fullName as string;
    await addDoc(eventsColRef, event.toPlainObject());
}

export async function getEvent(eventId: string) {
    const docRef = doc(eventsColRef, eventId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return BusinessEvent.fromPlainObject(docSnap.data(), docSnap.id);
    } else {
        console.log("No such event found!");
        return Promise.reject("No event with id ");
    }
}

export const emptyEvent = () =>
    new BusinessEvent(
        "",
        "",
        "",
        "",
        new Date(),
        new Date(),
        TypeOfEvent.CONFERENCE,
        EventSetting.ONLINE,
        EventAdmissionType.FREE,
        "",
        "",
        "",
        [],
        "",
        "",
        ""
    );

export async function copyEventById(eventId: string) {
    const eventToCopy = await getEvent(eventId);
    const copy = Object.assign(emptyEvent(), eventToCopy, {id: null, nativeCalendarId: null}) as BusinessEvent;
    copy.image = await copyPhoto(eventToCopy.image) as string;
    return copy;
}

export async function updateEvent(event: BusinessEvent) {
    const eventRef = doc(eventsColRef, event.id as string);
    return await updateDoc(eventRef, event.toPlainObject());
}

export async function deleteEvent(id) {
    const eventRef = doc(eventsColRef, id);
    return await deleteDoc(eventRef);
}

export async function getUserEvents() {
    const currentUserId = Auth.currentUser?.uid;
    const querySnapshot = await getDocs(query(eventsColRef,
        where("creatorId", "==", currentUserId),
        orderBy("startDate", "desc")
    ));
    const events = new Array<BusinessEvent>();
    for (const doc of querySnapshot.docs) {
        events.push(BusinessEvent.fromPlainObject(doc.data(), doc.id));
    }
    return events;
}

export async function getEventsFilters() {
    const currentUserId = Auth.currentUser?.uid;
    if (currentUserId) {
        const eventDoc = await getDoc(eventsFiltersDoc(currentUserId));
        if (eventDoc.exists()) {
            return EventFilters.fromPlainObject(eventDoc.data());
        }
    }
    return new EventFilters();
}

export async function getEventStatsForCurrentUser() {
    const currentUserId = Auth.currentUser?.uid;
    if (currentUserId) {
        const statsSnap = await getDocs(eventsStatsColRef(currentUserId));
        const statsByEventId = new Map<string, any>();
        statsSnap.forEach(eventStat => statsByEventId.set(eventStat.id, eventStat.data()));
        return statsByEventId;
    }
}

export async function saveEventsFilters(filters: EventFilters) {
    const currentUserId = Auth.currentUser?.uid;
    if (currentUserId) {
        const eventDoc = await getDoc(eventsFiltersDoc(currentUserId));
        if (eventDoc.exists()) {
            await updateDoc(eventDoc.ref, filters.toPlainObject());
        } else {
            await setDoc(eventsFiltersDoc(currentUserId), filters.toPlainObject());
        }
    }
}

async function buildEventsQuery(): Promise<Query<DocumentData>> {
    const filters = await getEventsFilters();
    const conditions = [where("endDate", ">=", new Date())];
    if (filters?.languages.length) {
        conditions.push(where("language", "in", filters.languages));
    }
    return query(eventsColRef, ...conditions, orderBy("endDate", "asc"));
}

async function getFilteredEvents(resultsSnap: QuerySnapshot<DocumentData>) {
    const filters: EventFilters = await getEventsFilters();
    let events = resultsSnap.docs.map(d => BusinessEvent.fromPlainObject(d.data(), d.id));
    if (!filters) {
        return events;
    }
    if (filters.settings.length) {
        events = events.filter(e => filters.settings.includes(e.setting));
    }
    if (filters.typesOfEvent.length) {
        events = events.filter(e => filters.typesOfEvent.includes(e.typeOfEvent));
    }
    if (filters.admissionTypes.length) {
        events = events.filter(e => filters.admissionTypes.includes(e.admissionType));
    }
    if (filters.hashtags.length) {
        events = events.filter(e => e.keywords.some(k => filters.hashtags.map(h => h.value).includes(k.value)));
    }
    if (events.length) {
        const viewedEvents = await getEventStatsForCurrentUser();
        events.forEach((e: BusinessEvent) => e.dateVisited = viewedEvents?.has(e.id as string) ? viewedEvents?.get(e.id as string).lastViewDate : undefined);
    }
    events.sort((e1, e2) => e1.startDate?.getTime() - e2.startDate?.getTime());
    return events;
}

export async function observeEvents(listener: (events) => void) {
    return onSnapshot(await buildEventsQuery(), async (resultsSnap) => {
        const events = await getFilteredEvents(resultsSnap);
        listener(events);
    });
}

export async function observeEventsFilters(listener: (filters) => void) {
    const profileId = Auth.currentUser?.uid;
    if (profileId) {
        return onSnapshot(eventsFiltersDoc(profileId), async (docSnap) => {
            const eventDocs = await getDocs(await buildEventsQuery());
            listener(await getFilteredEvents(eventDocs));
        });
    }
}

async function updateViewCounter(eventId: string) {
    const eventRef = doc(eventsColRef, eventId);
    const event: any = (await getDoc(eventRef)).data();
    const stats = {
        viewCount: (event.stats?.viewCount || 0) + 1,
        lastViewDate: new Date()
    };
    await updateDoc(eventRef, {stats});
}

export async function updateEventViewStats(eventId: string) {
    const currentUserId = Auth.currentUser?.uid;
    if (currentUserId) {
        const eventDoc = await getDoc(eventsStatsDoc(currentUserId, eventId));
        if (eventDoc.exists()) {
            const newCount = {
                viewCount: increment(1),
                lastViewDate: new Date()
            };
            await updateDoc(eventDoc.ref, newCount);
        } else {
            const counter = {viewCount: 1, linkClickCounter: 0, lastViewDate: new Date()};
            await setDoc(eventsStatsDoc(currentUserId, eventId), counter);
        }
        await updateViewCounter(eventId);
    }
}

export async function updateEventLinkStats(eventId: string) {
    const eventRef = doc(eventsColRef, eventId);
    await updateDoc(eventRef, {"stats.linkClickCounter": increment(1)});
}

export async function updateEventPhoto(photo: Photo) {
    if (photo.base64String) {
        const authUser = store.state.authUser;
        const photoRelativeDir = `images/events/${authUser.userInfo.uid || new Date().getTime().toString()}`;
        const photoRelativeUrl = `${photoRelativeDir}/${new Date().getTime().toString()}.${photo.format}`;
        const imagesRef = ref(Storage, photoRelativeUrl);
        const imageBlob = new Blob([new Uint8Array(decode(photo.base64String))], {
            type: `image/${photo.format}`,
        });
        const uploadResult = await uploadBytes(imagesRef, imageBlob);
        const downloadUrl = await getDownloadURL(uploadResult.ref);
        return downloadUrl;
    }
}

export async function removeEventPhoto(url) {
    if (url) {
        const imageRef = ref(Storage, url);
        try {
            await deleteObject(imageRef);
        } catch (e) {
            console.warn("Failed to remove image from storage", e);
        }
    }
}

async function copyPhoto(url: string): Promise<string | undefined> {
    if (url) {
        const originImageRef = ref(Storage, url);
        const fileExtension = originImageRef.fullPath.split(".").pop() || '';
        const newUrl = originImageRef.fullPath.replace("." + fileExtension, `-copy.${fileExtension}`);
        const imageRef = ref(Storage, newUrl);
        try {
            const imageBlob = await getBlob(originImageRef);
            const uploadResult = await uploadBytes(imageRef, imageBlob);
            return await getDownloadURL(uploadResult.ref);
        } catch (e) {
            console.warn("Failed to copy image in storage", e);
        }
    }
}
