import {Storage} from '@ionic/storage';
import {AuthToken, AuthUser, AuthUserInfo, CurrentUserSettings, FcmToken} from "@/store/index";

const storage = new Storage();

async function createStorage() {
    return await storage.create();
}

async function loadUser() {
    const userPlain = await storage.get('user');
    if (userPlain) {
        const authUser = new AuthUser();
        authUser.userInfo = Object.assign(new AuthUserInfo(), userPlain.userInfo);
        authUser.tokenInfo = Object.assign(new AuthToken(), userPlain.tokenInfo);
        authUser.tenant = userPlain.tenant;
        authUser.providerData = (userPlain.providerData || []).map((data: any) => Object.assign(new AuthToken(), data));
        return authUser;
    } else {
        return new AuthUser();
    }
}

async function saveUser(user: AuthUser) {
    await storage.set('user', user.toPlainObject());
    const saved = await loadUser()
}

async function saveFcmToken(fcmToken: FcmToken) {
    await storage.set('fcmToken', fcmToken.toPlainObject());
}

async function loadFcmToken() {
    const fcmToken = await storage.get('fcmToken');
    return Object.assign(new FcmToken(), fcmToken);
}

async function saveSettings(settings: CurrentUserSettings) {
    const currentSettings = await storage.get('settings');
    await storage.set('settings', {...currentSettings, ...settings});
}

async function loadSettings() {
    return await storage.get('settings') || {};
}

export {
    createStorage,
    saveUser,
    loadUser,
    saveFcmToken,
    loadFcmToken,
    saveSettings,
    loadSettings
}