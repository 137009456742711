import {FcmToken, Mastership, PersonalProfile, ProfessionalInfo, Rating} from "mastermatch-shared";
import {Analytics, Auth, Firestore, Messaging, Storage} from "@/firebase/credentials";
import {logEvent, setUserProperties} from "firebase/analytics";
import {State, store} from "@/store";
import {Photo} from "@capacitor/camera";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {decode} from "base64-arraybuffer";
import {Share} from '@capacitor/share';
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp,
    updateDoc,
    where
} from "firebase/firestore";
import {getToken} from 'firebase/messaging';
import {registerServiceWorker} from "@/registerServiceWorker";
import {MUTATIONS} from "@/store/mutations";

const personalProfileCol = collection(Firestore, PersonalProfile.collectionName);

export async function getCurrentProfile(uid?: string) {
    const currentUserId = uid || Auth.currentUser?.uid;
    if (!currentUserId) {
        return new PersonalProfile();
    }
    const personalProfileRef = doc(personalProfileCol, currentUserId);
    const profileDoc = await getDoc(personalProfileRef);
    if (profileDoc.exists()) {
        const profile = PersonalProfile.fromPlainObject(profileDoc.data(), profileDoc.id);
        if (!profile.imageUrl) {
            profile.imageUrl = Auth.currentUser?.photoURL || null;
        }
        if (!profile.phoneNumber) {
            profile.phoneNumber = Auth.currentUser?.phoneNumber || null;
        }
        return profile;
    }
    return null;
}

export async function getProfile(profileId: string): Promise<PersonalProfile> {
    const docRef = doc(personalProfileCol, profileId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return PersonalProfile.fromPlainObject(docSnap.data(), docSnap.id);
    } else {
        console.log("No such profile found!");
        return Promise.reject("No profile with id " + profileId);
    }
}

export async function getProfessionalInfo(profileId: string): Promise<ProfessionalInfo> {
    const docRef = doc(personalProfileCol, profileId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return ProfessionalInfo.fromPlainObject(docSnap.get('professionalInfo'));
    } else {
        console.log("No such profile found!");
        return Promise.reject("No profile with id " + profileId);
    }
}

export async function saveProfile(profile: PersonalProfile) {
    const authUser = store.state.authUser;
    if (!profile.imageUrl) {
        profile.imageUrl = authUser.userInfo.photoUrl;
    }
    if (authUser.userInfo.uid) {
        const profileRef = doc(personalProfileCol, authUser.userInfo.uid);
        const profileData = profile.markIfReadyForMatching().toPlainObject();
        await updateDoc(profileRef, profileData);
        logEvent(Analytics, 'profile_saved');
        if (profile.companyInfo?.position) {
            setUserProperties(Analytics, {position: profile.companyInfo?.position.value});
        }
    }
    return profile;
}

export async function saveInvitationCode(profile: PersonalProfile) {
    const profileRef = doc(personalProfileCol, profile.id);
    await updateDoc(profileRef, profile.asReferralUpdate());
    console.debug("Invitation code saved");
}

export async function markAsOrganization(isOrganization) {
    const profileId = Auth.currentUser?.uid;
    const profileRef = doc(personalProfileCol, profileId);
    await updateDoc(profileRef, {isOrganization});
}

export async function saveAccountSettings(profile: PersonalProfile) {
    const profileRef = doc(personalProfileCol, profile.id);
    await updateDoc(profileRef, profile.asAccountSettingsUpdate());
    console.debug("Account settings saved");
}

export function observeProfileChanges(callback: (profile: PersonalProfile) => void) {
    const profileId = Auth.currentUser?.uid;
    if (personalProfileCol && profileId) {
        return onSnapshot(doc(personalProfileCol, profileId), (snapshot => {
            callback(PersonalProfile.fromPlainObject(snapshot.data(), snapshot.id));
        }));
    }
}

export async function savePhoneNumber(profile: PersonalProfile) {
    const profileId = Auth.currentUser?.uid;
    if (profileId) {
        await updateDoc(doc(personalProfileCol, profileId), profile.asPhoneNumberUpdate());
    }
}

export async function saveScheduleSettings(profile: PersonalProfile) {
    const profileId = Auth.currentUser?.uid;
    if (profileId) {
        await updateDoc(doc(personalProfileCol, profileId), profile.asScheduleSettingsUpdate());
    }
}

export async function saveAdditionalInfo(profile: PersonalProfile) {
    const profileId = Auth.currentUser?.uid;
    if (profileId) {
        await updateDoc(doc(personalProfileCol, profileId), profile.asAdditionalInfoUpdate());
        try {
            logEvent(Analytics, 'additional_info_saved', profile.additionalInfo.toPlainObject());
        } catch (e) {
            console.warn("Could not send GA event", e)
        }
        console.debug("Saved additional info")
    }
}

export function setNotificationIfNeeded() {
    const userProfile = store.getters.userProfile;
    const notificationToDisplay = userProfile?.additionalInfo.getNotificationToDisplay(userProfile.isOrganization);
    if (notificationToDisplay) {
        setTimeout(() => {
            store.commit(MUTATIONS.SET_PUSH_NOTIFICATION, notificationToDisplay);
            logEvent(Analytics, 'show_notification', {notification: notificationToDisplay})
        }, 500);
    }
}

export async function deleteProfile(uid: string) {
    if (uid) {
        const profileRef = doc(personalProfileCol, uid);
        await updateDoc(profileRef, PersonalProfile.asDeletedUpdate());
    }
}

//TODO test 5MB
const MAX_IMAGE_SIZE_BYTES = 5 * 2 ** 20;

export async function updateProfilePhoto(photo: Photo) {
    if (photo.base64String) {
        const authUser = store.state.authUser;
        const photoRelativeDir = `images/users/${authUser.userInfo.uid || new Date().getTime().toString()}`;
        const photoRelativeUrl = `${photoRelativeDir}/profile.${photo.format}`;
        const imagesRef = ref(Storage, photoRelativeUrl);
        const imageBlob = new Blob([new Uint8Array(decode(photo.base64String))], {
            type: `image/${photo.format}`,
        });

        if (imageBlob.size > MAX_IMAGE_SIZE_BYTES) {
            //TODO image size validation toast
            throw new Error(`Image to exceeds maximum size of ${MAX_IMAGE_SIZE_BYTES}`)
        }
        const uploadResult = await uploadBytes(imagesRef, imageBlob);
        let downloadUrl = await getDownloadURL(uploadResult.ref);
        try {
            const resizedPhotoRelativeUrl = `${photoRelativeDir}/profile_400x400.${photo.format}`;
            downloadUrl = await getDownloadURL(ref(Storage, resizedPhotoRelativeUrl));
        } catch (e) {
            console.warn("Could not get minified photo url, falling back to original file")
        }

        if (authUser.userInfo.uid) {
            await updateDoc(doc(personalProfileCol, authUser.userInfo.uid), {
                imageUrl: downloadUrl
            });
        }
        logEvent(Analytics, 'change_photo');
        return downloadUrl;
    }
}

export async function deleteProfilePhoto(profile: PersonalProfile) {
    if (profile.id) {
        await updateDoc(doc(personalProfileCol, profile.id), {
            imageUrl: ''
        });
        profile.imageUrl = '';
    }
}

export async function registerForPushNotifications(state: State, callback: (token: string) => void) {
    const userId = Auth.currentUser?.uid;
    if (!userId) {
        return console.log("User id null, cannot register FCM");
    }
    registerServiceWorker(async (serviceWorkerRegistration) => {
        const tokensColRef = collection(personalProfileCol, userId, FcmToken.collectionName);
        if (state.fcmToken?.isValidForUser(userId)) {
            if (!state.fcmToken.isExpiringSoon()) {
                return;
            }
            const querySnap = await getDocs(query(tokensColRef, where("token", "==", state.fcmToken?.token)));
            if (querySnap.size > 0) {
                querySnap.forEach((tokenSnap) => {
                    updateDoc(tokenSnap.ref, {
                        timestamp: serverTimestamp()
                    });
                    const tokenData = tokenSnap.data();
                    console.log("Updating FCM token timestamp ", tokenData);
                    callback(tokenData.token);
                });
                return;
            }
        }
        const {VUE_APP_FCM_VAPID_KEY: vapidKey} = process.env;

        getToken(Messaging, {vapidKey, serviceWorkerRegistration}).then(async (currentToken) => {
            if (currentToken) {
                console.log('Device token requested successfully', currentToken);
                if (state.fcmToken?.token) {
                    const querySnap = await getDocs(query(tokensColRef, where("token", "==", currentToken)));
                    console.log("Removing old FCM tokens for profileId ", userId);
                    querySnap.forEach((tokenSnap) => deleteDoc(tokenSnap.ref));
                }
                await addDoc(tokensColRef, {
                    token: currentToken,
                    timestamp: serverTimestamp()
                });
                callback(currentToken);
            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        }).catch((err) => {
            console.warn('An error occurred while retrieving token. ', err);
        });

    });
}

export async function shareInvitationLink(profile: PersonalProfile) {
    if (profile.referral?.referralCode) {
        const shareResult = await Share.share({
            title: profile.professionalInfo.firstName + ' invites you to MasterMatch!',
            text: 'Join MasterMatch - online community of experts sharing their paid knowledge with each other',
            url: profile.referral.shareableLink,
            dialogTitle: 'Share your invitation link',
        });
        logEvent(Analytics, 'share', {method: shareResult.activityType, content_type: 'invitation_link'});
    }
}

function getRatingsQuery(profileId?: string) {
    const userId = profileId || store.state.userProfile.id;
    return query(collection(personalProfileCol, `${userId}`, Rating.collectionName), orderBy("ratedAt", "desc"));
}

export async function getRatings(profileId?: string) {
    const ratings: Rating[] = [];
    const querySnapshot = await getDocs(getRatingsQuery(profileId));
    querySnapshot.forEach((doc) => {
        ratings.push(Rating.fromPlainObject(doc.data()));
    });
    return ratings;
}

export async function getMastership(masterId?: string): Promise<Mastership> {
    const profileId = masterId || store.state.userProfile.id as string;
    if (!profileId) {
        return new Mastership();
    }
    const mastershipDoc = await getDoc(doc(personalProfileCol, profileId, Mastership.collectionName, profileId));
    if (mastershipDoc.exists()) {
        return Mastership.fromPlainObject(mastershipDoc.data());
    }
    return new Mastership();
}

export function observeMastershipChanges(callback: (mastership: Mastership) => void, masterId?: string,) {
    const profileId = masterId || Auth.currentUser?.uid;
    if (profileId) {
        return onSnapshot(doc(personalProfileCol, profileId, Mastership.collectionName, profileId), (snapshot => {
            callback(snapshot.exists() ? Mastership.fromPlainObject(snapshot.data()) : new Mastership());
        }));
    }
}