import {isPlatform} from "@ionic/core";

export const platformMixin = {

    computed: {
        $ios() {
            return isPlatform('ios');
        },

        $lgAndUp() {
            return window.innerWidth >= 1008;
        },
        $mdAndUp() {
            return window.innerWidth >= 768;
        },
        $smAndUp() {
            return window.innerWidth >= 576;
        }
    }
}