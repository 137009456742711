import {DEFAULT_LANGUAGE, Inquiry, LocalizedText, PersonalProfile} from "mastermatch-shared";
import {postJson} from "@/common/apiRestTemplate";
import {countries, languages} from "countries-list";


export const supportedInputLanguages = Object.freeze({
    'en': [languages.en, countries.GB.emoji],
    'pl': [languages.pl, countries.PL.emoji],
    'uk': [languages.uk, countries.UA.emoji],
    'ru': [languages.ru, countries.RU.emoji],
});

export async function translateText(text: string | string[] | null) {
    if (!text) {
        return text;
    }
    return await postJson('translate', {text});
}

export async function detectLanguage(text: string) {
    return await postJson('detect-language', {text});
}

export async function translateInquiry(inquiry: Inquiry) {
    const problemDescription = inquiry.getProblemDescription();
    const detectedLanguage = await detectLanguage(problemDescription);
    if (detectedLanguage != DEFAULT_LANGUAGE) {
        const translations = await translateText([inquiry.titleValue, inquiry.problemValue, inquiry.effortMadeValue, inquiry.adviceNeededValue]);
        inquiry.title?.setDefaultTranslation(translations[0]);
        inquiry.problem?.setDefaultTranslation(translations[1]);
        inquiry.effortMade?.setDefaultTranslation(translations[2]);
        inquiry.adviceNeeded?.setDefaultTranslation(translations[3]);
    } else {
        inquiry.title = LocalizedText.fromString(inquiry.titleValue);
        inquiry.problem = LocalizedText.fromString(inquiry.problemValue);
        inquiry.effortMade = LocalizedText.fromString(inquiry.effortMadeValue);
        inquiry.adviceNeeded = LocalizedText.fromString(inquiry.adviceNeededValue);
    }
    inquiry.inputLanguage = detectedLanguage;
    return inquiry;
}

export async function translateProfile(profile: PersonalProfile) {
    const info = profile.professionalInfo;
    if (info.isInDefaultLanguage() || !info.descriptionValue.length) {
        return;
    }
    const positionValue = profile.companyInfo.positionValue;
    const detectionText = !!positionValue ? `${positionValue}. ${info.descriptionValue}` : info.descriptionValue;
    const detectedLanguage = info.inputLanguage || await detectLanguage(detectionText);
    if (detectedLanguage != DEFAULT_LANGUAGE) {
        if (!!positionValue) {
            const [pos, desc] = await translateText([positionValue, info.descriptionValue])
            info.description?.setDefaultTranslation(desc);
            profile.companyInfo.position?.setDefaultTranslation(pos);
        } else {
            const translation = await translateText(info.descriptionValue)
            info.description?.setDefaultTranslation(translation);
        }
    }
    info.inputLanguage = detectedLanguage;
    return info;
}