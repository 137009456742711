import {
    DEFAULT_LANGUAGE,
    Inquiry,
    InquiryNotification,
    InquiryProposal,
    InquirySolver,
    InquiryStatus,
    LabeledId,
    Mastership,
    MatchingProposal,
    Meeting,
    MeetingInfo,
    MeetingStatus,
    PersonalProfile,
    ProposalStatusOfUser,
    Rating
} from "mastermatch-shared";
import {Auth, Firestore} from "@/firebase/credentials";
import {store} from "@/store";
import {getJson, postJson} from "@/common/apiRestTemplate";
import {
    collection,
    collectionGroup,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
    where,
    increment
} from "firebase/firestore";
import {getMeeting} from "@/common/meetingService";
import {getMastership, getProfile} from "@/common/userService";

const inquiriesCol = collection(Firestore, Inquiry.collectionName);
const meetingsCol = collection(Firestore, Meeting.collectionName);
const profilesCol = collection(Firestore, PersonalProfile.collectionName);
const ratingColRef = (profileId: string) => collection(doc(profilesCol, profileId), Rating.collectionName);
const proposalsColGroupRef = collectionGroup(Firestore, MatchingProposal.collectionName);
const proposalRef = (inquiryId: string, proposalId: string) => doc(inquiriesCol, inquiryId, MatchingProposal.collectionName, proposalId);

export async function searchKeywords(term: string, language: string | null = null) {
    if (!term || term === 'null') {
        return [];
    }
    const params = new URLSearchParams({term});
    if (language) {
        params.append('language', language);
    }
    return await getJson('search-keywords', params);
}

const skillCategories = new Map<string, LabeledId[]>();

export async function getSkillCategories(language?: string | null): Promise<LabeledId[]> {
    const targetLang = language || 'en';
    if (skillCategories.has(targetLang)) {
        return skillCategories.get(targetLang) as LabeledId[];
    }
    let params = null;
    if (language) {
        (params as any) = new URLSearchParams({language});
    }
    const categories: LabeledId[] = await getJson('skill-categories', params) as LabeledId[];
    return skillCategories
        .set(targetLang, categories)
        .get(targetLang) as LabeledId[];
}

export async function extractKeywords(text: string, targetLanguage: string | null, limit: number = 10): Promise<any[]> {
    // limit = limit || text.split(' ').length > 40 ? 10 : 10;
    targetLanguage = targetLanguage || DEFAULT_LANGUAGE;
    const params = {
        text: text,
        language: targetLanguage,
        limit: limit.toString()
    };
    return await postJson('extract-keywords', params);
}

export async function sendInquiry(inquiry: Inquiry) {
    return await postJson('inquiries', inquiry.toPlainObject());
}

function createInquiriesQuery() {
    const currentUserId = Auth.currentUser?.uid;
    return query(inquiriesCol,
        where("user.profileId", "==", currentUserId),
        where("status", "in", [InquiryStatus.NEW, InquiryStatus.MATCHED]),
        orderBy("createdAt", "desc"));
}

function createNewProposalsQuery() {
    const currentUserId = Auth.currentUser?.uid;
    return query(proposalsColGroupRef,
        where("inquiryUser.profileId", "==", currentUserId),
        where("inquiryUser.inquiryStatus", "in", [InquiryStatus.MATCHED, InquiryStatus.NEW]),
        where("status.statusOfMaster", "==", ProposalStatusOfUser.ACCEPTED),
        where("status.statusOfUser", "==", ProposalStatusOfUser.NONE),
    );
}

export async function getUserInquiries() {
    const inquiries = new Array<Inquiry>();
    const resultsSnap = await getDocs(createInquiriesQuery());
    resultsSnap.forEach((record) => {
        const inquiry = Inquiry.fromPlainObject(record.data(), record.id);
        inquiries.push(inquiry);
    });
    return inquiries;
}

export async function getUserInquiriesCount(profileId?: string) {
    const currentUserId = profileId || Auth.currentUser?.uid;
    const resultsSnap = await getDocs(query(inquiriesCol, where("user.profileId", "==", currentUserId)));
    return resultsSnap.size;
}

function completedMeetingsQuery() {
    return query(meetingsCol,
        where("userId", "==", store.getters.authUser.userInfo.uid),
        where("status", "in", [MeetingStatus.COMPLETED]),
        orderBy("createdAt", "desc"));
}

function resolveInquiriesQuery() {
    return query(inquiriesCol,
        where("user.profileId", "==", store.getters.authUser.userInfo.uid),
        where("status", "in", [InquiryStatus.RESOLVED]),
        orderBy("createdAt", "desc"));
}

export async function getResolvedInquiries() {
    const inquiries = new Array<Inquiry>();

    const resultsSnap = await getDocs(resolveInquiriesQuery());
    resultsSnap.forEach((record) => {
        const inquiry = Inquiry.fromPlainObject(record.data(), record.id);
        inquiries.push(inquiry);
    });

    const meetingsDocs = await getDocs(completedMeetingsQuery());
    for (const meetingDoc of meetingsDocs.docs) {
        const inquiryId = meetingDoc.get('inquiryId');
        if (inquiries.some(inq => inq.id === inquiryId)) {
            continue;
        }
        const inqDoc = await getDoc(doc(inquiriesCol, inquiryId));
        if (inqDoc.exists()) {
            const inquiry = Inquiry.fromPlainObject(inqDoc.data(), inqDoc.id);
            inquiries.push(inquiry);
        }
    }
    console.debug(`Found ${inquiries.length} own inquiries`);

    const solvedInquiries = await getInquiriesSolvedBy(store.getters.authUser.userInfo.uid);
    console.debug(`Found ${solvedInquiries.length} solved inquiries`);
    return inquiries.concat(...solvedInquiries);
}

export function observeUserInquiries(listener: (inquiries: Inquiry[]) => void) {
    return onSnapshot(createInquiriesQuery(), (resultsSnap => {
        const inquiries = new Array<Inquiry>();
        resultsSnap.forEach((record) => {
            const inquiry = Inquiry.fromPlainObject(record.data(), record.id);
            inquiries.push(inquiry);
        });
        listener(inquiries);
    }));
}

export function observeUserInquiryProposals(listener: (inquiries: MatchingProposal[]) => void) {
    return onSnapshot(createNewProposalsQuery(), (resultsSnap => {
        const proposals = new Array<MatchingProposal>();
        resultsSnap.forEach((record) => {
            const proposal = MatchingProposal.fromPlainObject(record.data(), record.id);
            proposals.push(proposal);
        });
        listener(proposals);
    }));
}

export async function deleteInquiry(inquiryId: string) {
    await deleteDoc(doc(inquiriesCol, inquiryId));
}

export async function resolveInquiry(inquiryId: string) {
    const docRef = doc(inquiriesCol, inquiryId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        await updateDoc(docRef, Inquiry.asResolvedUpdate());
    }
}

export async function saveInquiryProgress(inquiry: Inquiry) {
    const docRef = doc(inquiriesCol, inquiry.id as string);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        await updateDoc(docRef, inquiry.asProgressUpdate());
    }
}

export async function getInquiry(inquiryId: string) {
    const docRef = doc(inquiriesCol, inquiryId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return Inquiry.fromPlainObject(docSnap.data(), inquiryId);
    } else {
        console.log("No such inquiry found!");
        return Promise.reject("No inquiry with id " + inquiryId);
    }
}

export function canEditInquiry(inquiry: Inquiry): boolean {
    return ![InquiryStatus.DELETED, InquiryStatus.RESOLVED, InquiryStatus.EXPIRED].includes(inquiry.status);

}

export async function getTwoUsersMatchingCount(askingUserEmail: string, matchedUserId: string) {
    const countSnap = await getDocs(query(collectionGroup(Firestore, MatchingProposal.collectionName),
        where('email', '==', askingUserEmail),
        where("assignedUserIds", "array-contains", matchedUserId)));
    return countSnap.size;
}

export async function getRating(masterId: string, ratingId: string) {
    const ratingDoc = await getDoc(doc(ratingColRef(masterId), ratingId));
    if (ratingDoc.exists()) {
        return Rating.fromPlainObject(ratingDoc.data(), ratingDoc.id);
    }
}

export async function saveRating(masterId: string, rating: Rating) {
    await updateDoc(doc(ratingColRef(masterId), rating.id), rating.recalculateScore().toPlainObject());
}

function getMasterAcceptedProposalsQuery(inquiryId: string, newOnly = false) {
    const constraints = [where("status.statusOfMaster", "==", ProposalStatusOfUser.ACCEPTED)];
    if (newOnly) {
        //TODO change to only NONE when in-app chat implemented
        constraints.push(
            where("status.statusOfUser", "in", [ProposalStatusOfUser.NONE, ProposalStatusOfUser.SHORTLISTED])
        );
    }
    return query(collection(inquiriesCol, inquiryId, MatchingProposal.collectionName), ...constraints);
}

function getInquiryProposalByMasterIdQuery(inquiryId: string, masterId: string) {
    return query(collection(inquiriesCol, inquiryId, MatchingProposal.collectionName), where("masterId", "==", masterId));
}

export async function getInquiryProposalByInquiryIdAnMeetingId(inquiryId: string, meetingId: string): Promise<InquiryProposal | undefined> {
    const querySnapshot = await getDocs(query(collection(inquiriesCol, inquiryId, MatchingProposal.collectionName), where("meeting.id", "==", meetingId)));
    for (const doc of querySnapshot.docs) {
        const proposal = MatchingProposal.fromPlainObject(doc.data(), doc.id);
        return new InquiryProposal(proposal, await getProfile(proposal.masterId), new Mastership());
    }
}

export async function getProposal(inquiryId: string, proposalId: string) {
    const proposalDoc = await getDoc(proposalRef(inquiryId, proposalId));
    if (proposalDoc.exists()) {
        return MatchingProposal.fromPlainObject(proposalDoc.data(), proposalDoc.id);
    }
    return null;
}

export async function getInquiriesSolvedBy(masterId: string) {
    const inqPromises = new Array<Promise<Inquiry>>();
    const proposalsSnapshot = await getDocs(query(collectionGroup(Firestore, MatchingProposal.collectionName),
        where('masterId', '==', masterId),
        where('meeting.status', '==', MeetingStatus.COMPLETED),
        where('status.statusOfMaster', '==', ProposalStatusOfUser.ACCEPTED)
    ));
    proposalsSnapshot.forEach(doc => inqPromises.push(getInquiry(doc.get('inquiryId'))));
    return await Promise.all(inqPromises);
}

export async function getInquirySolvers(inquiryId: string): Promise<InquirySolver[]> {
    const meetingPromises = [] as Promise<Meeting>[];
    const querySnapshot = await getDocs(getMasterAcceptedProposalsQuery(inquiryId));
    for (const doc of querySnapshot.docs) {
        const meetingData = doc.get('meeting');
        if (!!meetingData && meetingData.id) {
            const meetingInfo = MeetingInfo.fromPlainObject(meetingData);
            if (meetingInfo) {
                meetingPromises.push(getMeeting(meetingInfo.id));
            }
        }
    }
    const meetings = await Promise.all(meetingPromises);
    const solvers = new Array<InquirySolver>();
    const currentUserId = store.state.userProfile.id;
    for (const meeting of meetings) {
        const rating = await getRating(meeting.masterId, meeting.id as string);
        if (rating) {
            solvers.push({
                ratingId: meeting.id,
                ratingScore: rating?.score || 0,
                ratingWaiting: (meeting.masterId !== currentUserId && rating?.isWaiting()) || false,
                ...meeting
            });
        }
    }
    return solvers;
}

export async function observeInquiryProposals(inquiryId: string, observer: (proposals: InquiryProposal[]) => void) {
    return onSnapshot(getMasterAcceptedProposalsQuery(inquiryId), async (querySnapshot) => {
        const proposals = new Array<InquiryProposal>();
        for (const doc of querySnapshot.docs) {
            const proposal = MatchingProposal.fromPlainObject(doc.data(), doc.id);
            const [master, mastership] = await Promise.all([getProfile(proposal.masterId), getMastership(proposal.masterId)]);
            proposals.push(new InquiryProposal(proposal, master, mastership));
        }
        observer(proposals);
    });
}

export function observeNewInquiryProposals(inquiryId: string, listener: (proposals: MatchingProposal[]) => void) {
    return onSnapshot(getMasterAcceptedProposalsQuery(inquiryId, true), (qSnap) => {
        listener(qSnap.docs.map(doc => MatchingProposal.fromPlainObject(doc.data())));
    });
}

export type MatchingUpdate = {
    masterAccepted?: boolean,
    masterRejected?: boolean,
    shortlisted?: boolean,
    rejected?: boolean,
    meeting?: MeetingInfo | null;
};

export async function updateProposalStatus(inquiryId: string, masterId: string, update: MatchingUpdate) {
    const querySnapshot = await getDocs(getInquiryProposalByMasterIdQuery(inquiryId, masterId));
    querySnapshot.forEach((doc) => {
        const matchingProposal = MatchingProposal.fromPlainObject(doc.data());
        const updatePayload: any = {};
        if (update.shortlisted !== undefined) {
            matchingProposal.shortlistedByUser = update.shortlisted;
            updatePayload.status = matchingProposal.status.toPlainObject();
        }
        if (update.rejected !== undefined) {
            matchingProposal.rejectedByUser = update.rejected;
            updatePayload.status = matchingProposal.status.toPlainObject();
        }
        if (update.masterAccepted !== undefined) {
            matchingProposal.acceptedByMaster = update.masterAccepted;
            updatePayload.status = matchingProposal.status.toPlainObject();
        }
        if (update.masterRejected !== undefined) {
            matchingProposal.rejectedByMaster = update.masterRejected;
            updatePayload.status = matchingProposal.status.toPlainObject();
        }
        if (update.meeting !== undefined) {
            matchingProposal.meeting = update.meeting;
            updatePayload.meeting = update.meeting?.toPlainObject() || null;
        }
        updateDoc(doc.ref, updatePayload);
    });
}

export async function updateProposalViewStats(inquiryId: string, proposalId: string) {
    const proposalDoc = await getDoc(proposalRef(inquiryId, proposalId));
    if(proposalDoc.exists()) {
        await updateDoc(proposalDoc.ref, {viewCount: increment(1)})
    }
}

function createNotificationsQuery(statusesOfMaster = [ProposalStatusOfUser.NONE]) {
    const currentUserId = Auth.currentUser?.uid;
    statusesOfMaster = !!statusesOfMaster && statusesOfMaster.length ? statusesOfMaster : [ProposalStatusOfUser.NONE];
    return query(collectionGroup(Firestore, MatchingProposal.collectionName),
        where("inquiryStatus", "==", InquiryStatus.MATCHED),
        where("masterId", "==", currentUserId),
        where("status.assigned", "==", true),
        where("status.statusOfMaster", "in", statusesOfMaster),
        where("status.statusOfUser", "!=", ProposalStatusOfUser.REJECTED),
        orderBy("status.statusOfUser"),
        orderBy("updatedAt", "desc"));
}

export function observeUserNotifications(listener: (matches: InquiryNotification[]) => void, statusesOfMaster = [ProposalStatusOfUser.NONE]) {
    return onSnapshot(createNotificationsQuery(statusesOfMaster), async (resultsSnap) => {
        const notifications = new Array<InquiryNotification>();
        for (const doc of resultsSnap.docs) {
            const match = MatchingProposal.fromPlainObject(doc.data(), doc.id);
            if (match.meeting && match.meeting.status != MeetingStatus.SCHEDULED) {
                continue;
            }
            if (match.inquiryUser.profileId) {
                const userProfile = await getProfile(match.inquiryUser.profileId as string);
                notifications.push(new InquiryNotification(match, userProfile));
            } else {
                console.warn("Inquiry %s userId is not present: %s", match.inquiryId, match.inquiryUser);
                const inquiry = await getInquiry(match.inquiryId);
                const userProfile = await getProfile(inquiry.user.profileId as string);
                notifications.push(new InquiryNotification(match, userProfile));
            }
        }
        listener(notifications);
    });
}
