/*
 * Mutations
 * How we mutate our state.
 * Mutations must be synchronous
 */
import {MutationTree} from "vuex";
import {AuthUser, FcmToken, ProductItem, State} from "@/store/index";
import {saveFcmToken, saveSettings, saveUser} from "@/store/storage";
import {Subscription} from "chargebee-typescript/lib/resources";
import {User} from 'firebase/auth'
import {getCurrentProfile} from "@/common/userService";
import {Mastership, NotificationMessageCode, PersonalProfile, Tenant} from "mastermatch-shared";
import {logEvent} from "firebase/analytics";
import {Analytics} from "@/firebase/credentials";

export const enum MUTATIONS {
    SET_USER = 'SET_USER',
    SET_USER_PROFILE = 'SET_USER_PROFILE',
    SET_MASTERSHIP = 'SET_MASTERSHIP',
    UNSET_USER = 'UNSET_USER',
    SET_SUBSCRIPTION = 'SET_SUBSCRIPTION',
    SET_LINE_ITEMS = 'SET_LINE_ITEMS',
    SET_FCM_TOKEN = 'SET_FCM_TOKEN',
    PWA_INSTALL_ACK = 'PWA_INSTALL_ACK',
    SPLASH_SHOWN = 'SPLASH_SHOWN',
    SET_REFERRAL_CODE = 'SET_REFERRAL_CODE',
    SET_PUSH_NOTIFICATION = 'SET_PUSH_NOTIFICATION',
    SET_IS_ORGANIZATION = 'SET_IS_ORGANIZATION',
    SET_OPEN_MODAL = 'SET_OPEN_MODAL',
    SAVE_LANGUAGE = 'SAVE_LANGUAGE',
}

const mutations: MutationTree<State> = {
    async [MUTATIONS.SET_USER](state, user: User) {
        const idToken = await user.getIdTokenResult(false);

        //TODO UA multitenancy workaround
        const profile = await getCurrentProfile();
        if (profile && profile.tenant == Tenant.UA) {
            idToken.claims.tenant = Tenant.UA;
            state.features.ua = true;
        }
        //end workaround

        state.authUser = new AuthUser(user, idToken);
        await saveUser(state.authUser);
    },

    [MUTATIONS.SET_USER_PROFILE](state, profile: PersonalProfile) {
        state.userProfile = profile;
    },
    [MUTATIONS.SET_MASTERSHIP](state, mastership: Mastership | undefined) {
        state.mastership = mastership;
    },
    async [MUTATIONS.UNSET_USER](state) {
        state.authUser = new AuthUser();
        state.userProfile = new PersonalProfile();
        await saveUser(state.authUser);
    },
    [MUTATIONS.SET_SUBSCRIPTION](state, subscription: Subscription) {
        state.subscriptionInfo.subscription = subscription;
    },
    [MUTATIONS.SET_LINE_ITEMS](state, productItems: ProductItem[]) {
        state.subscriptionInfo.lineItems = productItems;
    },
    async [MUTATIONS.SET_FCM_TOKEN](state, token: string) {
        state.fcmToken = new FcmToken(token, state.authUser.userInfo.uid);
        await saveFcmToken(state.fcmToken);
    },
    async [MUTATIONS.PWA_INSTALL_ACK](state) {
        state.settings.pwaAcknowledged = new Date();
        await saveSettings(state.settings);
    },
    async [MUTATIONS.SPLASH_SHOWN](state) {
        state.settings.splashShown = true;
        logEvent(Analytics, 'splash_shown');
        await saveSettings(state.settings);
    },
    [MUTATIONS.SET_REFERRAL_CODE](state, code: string) {
        state.referralCode = code;
    },
    [MUTATIONS.SET_PUSH_NOTIFICATION](state, notificationCode: NotificationMessageCode) {
        state.pushNotification = notificationCode;
    },
    [MUTATIONS.SET_IS_ORGANIZATION](state, isOrganization) {
        state.isOrganization = isOrganization;
    },
    async [MUTATIONS.SAVE_LANGUAGE](state, languageCode: string) {
        state.settings.languageCode = languageCode;
        await saveSettings(state.settings);
    },
    [MUTATIONS.SET_OPEN_MODAL](state, name: string) {
        state.openModal = name;
    },
};

export default mutations;