import {getJson, postJson} from "@/common/apiRestTemplate";
import {Firestore} from "@/firebase/credentials";
import {store} from "@/store";
import {MUTATIONS} from "@/store/mutations";
import {ItemPrice, PortalSession} from "chargebee-typescript/lib/resources";
import {collection, onSnapshot, QuerySnapshot} from 'firebase/firestore'
import {PersonalProfile} from "mastermatch-shared";

const CHARGEBEE_SITE = process.env.VUE_APP_CHARGEBEE_API_SITE;
const profilesCol = collection(Firestore, PersonalProfile.collectionName);

export async function fetchAvailablePlans(currency: string | null = null): Promise<ItemPrice[]> {
    return await getJson('chargebee/products', currency ? new URLSearchParams({currency}) : null);
}

export async function fetchSubscriptionInfoFromServer() {
    const subscriptionInfo = store.state.subscriptionInfo;
    if (!subscriptionInfo || !subscriptionInfo.subscription) {
        const subscriptionInfo = await getJson('chargebee/user-subscription');
        if (subscriptionInfo) {
            console.debug("Fetched subscription info from server", subscriptionInfo);
            return subscriptionInfo;
        }
    }
}

export async function setSubscriptionInfo(querySnap: QuerySnapshot) {
    const subscription = querySnap.docs.map(doc => doc.data()).pop();
    if (!subscription) {
        console.log("No subscription object found in the DB");
        const subscriptionInfo = process.env.NODE_ENV == 'development' ? await fetchSubscriptionInfoFromServer() : null;
        store.commit(MUTATIONS.SET_SUBSCRIPTION, subscriptionInfo?.subscription);
        return;
    }
    store.commit(MUTATIONS.SET_SUBSCRIPTION, subscription);
    console.log("Set subscription", subscription);
}

export async function setLineItems(querySnap: QuerySnapshot) {
    const lineItems = querySnap.docs.map(doc => doc.data());
    if (lineItems.length) {
        store.commit(MUTATIONS.SET_LINE_ITEMS, lineItems);
        console.log("Set lineItems", lineItems);
    } else {
        console.log("No lineItems found in the db");
        const subscriptionInfo = process.env.NODE_ENV == 'development' ? await fetchSubscriptionInfoFromServer() : null;
        store.commit(MUTATIONS.SET_LINE_ITEMS, subscriptionInfo?.lineItems);
    }
}

export function observeSubscriptionChanges(userId: string) {
    onSnapshot(collection(profilesCol, userId, "subscriptions"), (querySnap) => setSubscriptionInfo(querySnap));
    onSnapshot(collection(profilesCol, userId, "lineItems"), (querySnap) => setLineItems(querySnap));
}

let selfServicePortalSession: PortalSession | null = null;

export async function getSelfServicePortalSession() {
    if (!store.state.subscriptionInfo) {
        return null;
    }
    if (selfServicePortalSession != null) {
        const isExpired = (selfServicePortalSession.expires_at || 0) * 1000 < new Date().getTime();
        if (!isExpired) {
            return selfServicePortalSession;
        }
    }
    selfServicePortalSession = await getJson('chargebee/portal-session') as PortalSession;
    return selfServicePortalSession;
}

declare const Chargebee: any;
let cbInstance: any;
let cbPortal: any;

export async function initChargebeeInstance() {
    if (!cbInstance) {
        cbInstance = Chargebee.init({
            site: CHARGEBEE_SITE,
            isItemsModel: true,
        })
    }
    const servicePortalToken = await getSelfServicePortalSession()
    cbInstance.setPortalSession(servicePortalToken);
    cbPortal = cbInstance.createChargebeePortal();
}

export function openPortal() {
    if (cbPortal) {
        cbPortal.open();
    }
}

export function closeSubscriptionModals() {
    return cbInstance && cbInstance.closeAll();
}

export async function buildCart(profile: PersonalProfile, successFn: (() => void) | null = null) {
    await initChargebeeInstance();
    if (successFn) {
        cbInstance.setCheckoutCallbacks(function () {
            return {
                success: successFn,
                error: function (e: any) {
                    console.log('cart error', e)
                }
            }
        });
    }
    const cart = cbInstance.getCart();
    /* eslint-disable @typescript-eslint/camelcase */
    const customer = {
        id: profile.id,
        email: profile.email,
        first_name: profile.professionalInfo.firstName,
        last_name: profile.professionalInfo.lastName,
        // billing_address: {first_name: "", last_name: "", company: "", phone: "", line1: "", line2: ""}
    };
    cart.setCustomer(customer);
    cart.setCustomer(customer);
    return cart;
}

export function openCheckout(options: { productId: string; chargeId: string | null; coupon: string[] | string | null }) {
    const cart = cbInstance.getCart();
    const product = cbInstance.initializeProduct(options.productId, 1);
    if (options.chargeId) {
        product.addCharge(options.chargeId);
    }
    if (options.coupon) {
        const coupons = options.coupon instanceof Array ? options.coupon : [options.coupon];
        for (const coupon of coupons) {
            if (!product.coupons.includes(coupon)) {
                product.addCoupon(coupon);
            }
        }
    }
    cart.replaceProduct(product);
    cart.proceedToCheckout();
}

export async function addCharge(chargeId: string) {
    const response = await postJson("chargebee/user-packages", {chargeId});
    console.log('Charge response successful', response);
    if (response.invoiceStatus !== 'paid') {
        throw new Error("Payment method failed. " + response.statusMessage);
    }
}

export async function payInvoice(invoiceId: string) {
    const response = await postJson("chargebee/invoices/payments", {invoiceId});
    console.log('Pay invoice response successful', response);
    if (response.invoiceStatus !== 'paid') {
        throw new Error("Payment method failed. " + response.statusMessage);
    }
}

export function formatPrice(priceInCents: number | null | undefined, currency_code: string) {
    const price = (priceInCents || 0) / 100;
    return price.toLocaleString(undefined, {
        style: 'currency',
        currency: currency_code,
        maximumFractionDigits: 0
    });
}

