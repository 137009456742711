import {alertController} from "@ionic/vue";
import {Auth} from "@/firebase/credentials";
import {toast} from "@/common/alerts";
import {
    AuthError,
    fetchSignInMethodsForEmail,
    GoogleAuthProvider,
    linkWithCredential,
    OAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup
} from 'firebase/auth';
import {getInquiry, getRating} from "@/common/matchingService";
import {store} from "@/store";
import {getRouter} from "@/router";
import {i18n} from "@/i18n";
import {RatingStatus} from "mastermatch-shared";
import {getEvent} from "@/common/eventService";

export type LoginCredentials = { email: string; password: string; };

export function createLinkedinProvider() {
    const provider = new OAuthProvider('linkedin.com');
    provider.addScope('r_emailaddress');
    provider.addScope('r_liteprofile');
    provider.setCustomParameters({
        prompt: 'select_account'
    });
    return provider;
}

export function createGoogleAuthProvider() {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    provider.addScope('openid');
    provider.setCustomParameters({
        prompt: 'select_account'
    });
    return provider;
}

export function createAppleAuthProvider() {
    return new OAuthProvider('apple.com');
}

export function getProviderForProviderId(providerId: string) {
    switch (providerId) {
        case GoogleAuthProvider.PROVIDER_ID:
            return createGoogleAuthProvider();
        case "linkedin.com":
            return createLinkedinProvider();
        case "apple.com":
            return createAppleAuthProvider();
        default:
            throw new Error(`Provider id ${providerId} not supported`);
    }
}

export async function loginRedirect(defaultRoute = '/tabs/home') {
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("go")) {
        await getRouter().push({ path: `${searchParams.get("go")}` });
    } else {
        await getRouter().push(defaultRoute);
    }
}

async function promptUserForPassword() {
    let password = null;
    const alert = await alertController
        .create({
            cssClass: 'my-custom-class',
            header: i18n.global.t('alert.accountExists.header'),
            message: i18n.global.t('alert.accountExists.message'),
            inputs: [
                {
                    name: 'password',
                    type: 'password',
                    placeholder: 'Password',
                    cssClass: 'specialClass',
                    attributes: {
                        minLength: 6,
                    }
                }
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        console.log('Confirm Cancel');
                    },
                },
                {
                    text: 'Ok',
                    role: "ok",
                    handler: (data) => {
                        console.log('Confirm Ok');
                        password = data.password;
                    },
                },
            ],
        });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role == 'ok') {
        return password;
    }
}

function handleAccountExistsWithDifferentCredentials(error: AuthError) {
    // Step 2.
    // User's email already exists.
    const pendingCred = OAuthProvider.credentialFromError(error);
    // The provider account's email address.
    const email = error.customData.email;
    if (email && pendingCred) {
        // Get sign-in methods for this email.
        fetchSignInMethodsForEmail(Auth, email).then(async function (methods) {
            // Step 3.
            // If the user has several sign-in methods,
            // the first method in the list will be the "recommended" method to use.
            if (methods[0] === 'password') {
                // Asks the user their password.
                // In real scenario, you should handle this asynchronously.
                const password = await promptUserForPassword();
                if (!password) {
                    return;
                }
                signInWithEmailAndPassword(Auth, email, password).then(function (result) {
                    // Step 4a.
                    return result.user ? linkWithCredential(result.user, pendingCred) : null;
                }).then(function () {
                    getRouter().push("/");
                });
                return;
            }
            // All the other cases are external providers.
            // Construct provider object for that provider.
            const provider = getProviderForProviderId(methods[0]);
            // At this point, you should let the user know that they already have an account
            // but with a different provider, and let them validate the fact they want to
            // sign in with this provider.
            // Sign in to provider. Note: browsers usually block popup triggered asynchronously,
            // so in real scenario you should ask the user to click on a "continue" button
            // that will trigger the signInWithPopup.
            signInWithPopup(Auth, provider).then(function (result) {
                // Remember that the user may have signed in with an account that has a different email
                // address than the first one. This can happen as Firebase doesn't control the provider's
                // sign in flow and the user is free to login using whichever account they own.
                // Step 4b.
                // Link to Social credential.
                // As we have access to the pending credential, we can directly call the link method.
                if (result.user) {
                    linkWithCredential(result.user, pendingCred).then(function (usercred) {
                        // Social account successfully linked to the existing Firebase user.
                        console.log("Linked account with social provider", provider.providerId);
                        getRouter().push("/");
                    });
                }
            });
        });
    }
}

async function handleRequiresRecentLogin() {
    const alert = await toast({
        header: i18n.global.t('toast.auth.requiredAuth.header'),
        message: i18n.global.t('toast.auth.requiredAuth.message'),
        color: 'danger', position: "top"
    }, true);
    const { role } = await alert.onDidDismiss();
    if (role == 'ok') {
        await getRouter().push("/account/login");
    }
}

export const handleAuthError = async (error: AuthError) => {
    console.log("auth error", error.code);
    switch (error.code) {
        case 'auth/wrong-password':
        case 'auth/user-not-found':
            toast({

                header: i18n.global.t('toast.auth.wrongEmail.header'),
                message: i18n.global.t('toast.auth.wrongEmail.message'),
                color: 'warning',
                position: "top",
                duration: 5000
            });
            break;
        case 'auth/user-disabled':
            toast({
                header: i18n.global.t('toast.auth.disabledAccount.header'),
                message: i18n.global.t('toast.auth.disabledAccount.message'),
                color: 'warning',
                position: "top",
            }, true);
            break;
        case 'auth/invalid-email':
            toast({ header: i18n.global.t('toast.auth.wrongEmail.invalid'), color: 'warning', position: "top" });
            break;
        case 'auth/invalid-credential':
            console.log("Login credential error", error);
            toast({ header: i18n.global.t('toast.auth.invalidCredentials'), color: 'warning', position: "top" });
            break;
        case 'auth/account-exists-with-different-credential':
            await toast({
                message: i18n.global.t('toast.auth.existsAccount'),
                color: 'warning',
                position: "top"
            });
            handleAccountExistsWithDifferentCredentials(error);
            break;
        case 'auth/requires-recent-login':
            await handleRequiresRecentLogin();
            break;
        case 'auth/popup-closed-by-user':
            toast({ header: i18n.global.t('toast.auth.loginCanceled') });
            break;
        case 'auth/popup-blocked':
            toast({ header: i18n.global.t('toast.auth.popupBlock'), color: 'warning' });
            break;
        case 'auth/email-already-in-use':
            toast({ header: i18n.global.t('toast.auth.inUseEmail.header'), message: i18n.global.t('toast.auth.inUseEmail.message'), color: 'danger' });
            break;
        default:
            console.log("Unexpected login error", error);
            toast({ message: error.message, color: 'danger', position: "top" });
    }
};

export function handlePhoneVerificationError(error: AuthError) {
    console.error("Phone verification error", error.message);
    switch (error.code) {
        case 'auth/invalid-verification-code':
        case 'auth/invalid-verification-id':
            return "Invalid code";
        case 'auth/account-exists-with-different-credential':
            toast({
                header: i18n.global.t('toast.auth.phoneNumber.linkedDiffAccount.header'),
                message: i18n.global.t('toast.auth.phoneNumber.linkedDiffAccount.message'),
                color: "danger"
            });
            break;
        case 'auth/provider-already-linked':
            toast({
                header: i18n.global.t('toast.auth.phoneNumber.error.header'),
                message: i18n.global.t('toast.auth.phoneNumber.error.message'),
                color: "danger"
            });
            break;
        default:
            toast({
                header: i18n.global.t('toast.auth.phoneNumber.verifyingError.header'),
                message: i18n.global.t('toast.auth.phoneNumber.verifyingError.message', { errorCode: error.code }),
                color: "danger"
            });
    }

}


export enum ResourceType {
    INQUIRY = 'inquiries',
    MEETING = 'meetings'
}

export async function holdsOwnership(resourceType: ResourceType, resourceId: string) {
    const currentUserId = store.getters.authUser?.userInfo.uid;
    switch (resourceType) {
        case ResourceType.INQUIRY:
            const inquiry = await getInquiry(resourceId);
            return inquiry.user.profileId === currentUserId;
        case ResourceType.MEETING:
            //TODO implement
            return true;
        default:
            return false;
    }
}

export async function canRateMaster(masterId: string, ratingId: string) {
    const currentUserId = store.getters.authUser?.userInfo.uid;
    const rating = await getRating(masterId, ratingId);
    return rating?.ratedById === currentUserId && rating?.status !== RatingStatus.RATED;
}

export function canCreateEvents() {
    const userProfile = store.getters.userProfile;
    const { subscription } = store.getters.subscriptionInfo;
    //TODO crate own Subscription class extending Chargebee Subscription class
    const isSubscriptionActive = () =>
        subscription?.status?.toLowerCase() === 'active' ||
        (subscription?.status?.toLowerCase() === 'non_renewing' && new Date((subscription.cancelled_at as number) * 1000) > new Date());
    return userProfile.isProfileComplete() && userProfile.isOrganization && isSubscriptionActive();
}

export async function canEditEvent(eventId: string) {
    if(!canCreateEvents()) {
        return false;
    }
    const event = await getEvent(eventId);
    return event?.creatorId === Auth.currentUser?.uid;
}
