import { createI18n, I18nOptions } from "vue-i18n";
import {countries, languages} from "countries-list";
import 'moment/locale/pl'

import en from "./locales/en.json";
import pl from "./locales/pl.json";
import moment from "moment";

export const interfaceLanguages = Object.freeze({
    'en': [languages.en, countries.GB.emoji],
    'pl': [languages.pl, countries.PL.emoji],
});

export type SupportedLocales = 'en' | 'pl';
const messages = {
    "en": en,
    "pl": pl
}
const locale = 'pl';

export const i18n = createI18n<I18nOptions, SupportedLocales>({
    locale,
    messages
});

export function setLocale(localeCode: SupportedLocales | string | undefined) {
    if(!localeCode || localeCode === i18n.global.locale) {
        return i18n.global.locale;
    }
    if(localeCode in messages) {
        i18n.global.locale = localeCode;
        moment.locale(localeCode, {
            week: {
                dow: 1,
            },
        });
    } else {
        console.warn("Unsupported localeCode %s, falling back to '%s'", localeCode, locale)
    }
    return i18n.global.locale;
}

export function getCurrentLocale() {
    return i18n.global.locale;
}