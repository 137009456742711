import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteLocationRaw, RouteParams, Router, RouteRecordRaw} from 'vue-router';
import Tabs from '../views/Tabs.vue';
import {store} from "@/store";
import {MUTATIONS} from "@/store/mutations";
import {OnboardingStepCode, Tenant} from "mastermatch-shared";
import {canCreateEvents, canEditEvent, canRateMaster, holdsOwnership, ResourceType} from "@/auth/authService";
import {Analytics, Auth} from "@/firebase/credentials";
import {getCurrentProfile, setNotificationIfNeeded} from "@/common/userService";
import isEqual from "lodash-es/isEqual";
import {logEvent} from "firebase/analytics";

export type AuthCheck = (params: RouteParams) => Promise<boolean>;

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/tabs/home'
    },
    {
        path: '/tabs/',
        component: Tabs,
        children: [
            {
                path: '',
                redirect: '/tabs/home',
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'home',
                component: () => import(/* webpackChunkName: "group-main-views" */ '@/views/Home.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'notifications',
                component: () => import(/* webpackChunkName: "group-main-views" */ '@/views/Notifications.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'history',
                component: () => import(/* webpackChunkName: "group-main-views" */ '@/views/History.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'events',
                component: () => import(/* webpackChunkName: "group-main-views */'@/views/Events.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'events/:eventId',
                component: () => import(/* webpackChunkName: "group-main-views */'@/views/Events.vue'),
                props: true,
                meta: {
                    authRequired: true,
                },
            },
            {
                path: '/events/create',
                component: () => import(/* webpackChunkName: "group-events */'@/views/events/CreateEvent.vue'),
                props: route => ({
                    duplicatedId: route.query.duplicatedId,
                    eventId: null
                }),
                meta: {
                    authRequired: true,
                    authCheck: function () {
                        return canCreateEvents();
                    } as AuthCheck
                },
            },
            {
                path: '/events/:eventId/edit',
                component: () => import(/* webpackChunkName: "group-events */'@/views/events/CreateEvent.vue'),
                props: true,
                meta: {
                    authRequired: true,
                    authCheck: async function (params: RouteParams) {
                        return await canEditEvent(params.eventId as string);
                    } as AuthCheck
                }
            },
            {
                path: 'my-events',
                component: () => import(/* webpackChunkName: "group-events */'@/views/events/MyEvents.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: '/inquiries/create',
                component: () => import(/* webpackChunkName: "group-inquiry" */  '@/views/inquiries/CreateInquiry.vue'),
                props: route => ({problem: route.query.problem}),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: '/inquiries/thankyou',
                component: () => import(/* webpackChunkName: "group-inquiry" */'@/views/inquiries/ThankYou.vue'),
            },
            {
                path: '/inquiries/:inquiryId/edit',
                component: () => import(/* webpackChunkName: "group-inquiry" */'@/views/inquiries/CreateInquiry.vue'),
                props: true,
                meta: {
                    authRequired: true,
                    authCheck: function (params: RouteParams) {
                        return holdsOwnership(ResourceType.INQUIRY, params.inquiryId as string);
                    } as AuthCheck
                }
            },
            {
                path: '/profiles/:masterId/rating/:ratingId',
                component: () => import(/* webpackChunkName: "group-inquiry" */'@/views/inquiries/Rating.vue'),
                props: true,
                meta: {
                    authRequired: true,
                    authCheck: function (params: RouteParams) {
                        return canRateMaster(params.masterId as string, params.ratingId as string);
                    } as AuthCheck
                }
            },
            {
                path: '/inquiries/:inquiryId/proposals',
                component: () => import(/* webpackChunkName: "group-inquiry" */'@/views/inquiries/InquiryProposals.vue'),
                props: true,
                meta: {
                    authRequired: true,
                    authCheck: function (params: RouteParams) {
                        return holdsOwnership(ResourceType.INQUIRY, params.inquiryId as string);
                    } as AuthCheck
                }
            },
            {
                path: '/tabs/profile',
                component: () => import(/* webpackChunkName: "group-main-views" */ '@/views/Profile.vue'),
                props: route => ({
                    referralCode: route.query.referralCode,
                    activePanel: route.query.activePanel,
                }),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: '/:tenant/tabs/profile',
                component: () => import(/* webpackChunkName: "group-main-views" */ '@/views/Profile.vue'),
                props: route => ({
                    referralCode: route.query.referralCode,
                    tenant: route.params.tenant
                }),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: '/account/thankyou',
                component: () => import(/* webpackChunkName: "group-account" */ '@/views/account/ThankYou.vue'),
                meta: {
                    authRequired: true,
                },
            },
        ]
    },
    //full screen views
    {
        path: '/ua/inquiries/create',
        component: () => import(/* webpackChunkName: "group-inquiry" */ '@/views/inquiries/CreateInquiry.vue'),
        props: {
            tenant: Tenant.UA,
            internal: false
        }
    },
    {
        path: '/ua/inquiries/thankyou',
        component: () => import(/* webpackChunkName: "group-inquiry" */ '@/views/inquiries/ThankYou.vue'),
        props: {tenant: Tenant.UA},
    },
    {
        path: '/account/welcome',
        component: () => import( /* webpackChunkName: "group-account" */ '@/views/profile/Welcome.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/account/create',
        component: () => import(/* webpackChunkName: "group-account" */ '@/views/account/CreateAccount.vue'),
        beforeEnter: (to, from, next) => Auth.currentUser ? next('/') : next(),
        props: route => ({
            locale: route.query.locale
        }),
    },
    {
        path: '/organizations/account/create',
        component: () => import(/* webpackChunkName: "group-account" */ '@/views/account/CreateAccount.vue'),
        props: route => ({
            isOrganization: true,
            locale: route.query.locale
        }),
        beforeEnter: (to, from, next) => Auth.currentUser ? next('/') : next()
    },
    {
        path: '/ua/account/create',
        component: () => import(/* webpackChunkName: "group-account" */ '@/views/account/CreateAccount.vue'),
        props: route => ({
            referralCode: route.query.referralCode,
            tenant: Tenant.UA
        }),
        beforeEnter: (to, from, next) => Auth.currentUser ? next('/ua/tabs/home') : next()
    },
    {
        path: '/account/login',
        component: () => import(/* webpackChunkName: "group-account" */ '@/views/account/Login.vue'),
        beforeEnter: (to, from, next) => Auth.currentUser ? next('/') : next()
    },
    {
        path: '/ua/account/login',
        component: () => import(/* webpackChunkName: "group-account" */ '@/views/account/Login.vue'),
        props: {tenant: Tenant.UA},
        beforeEnter: (to, from, next) => Auth.currentUser ? next('/ua/tabs/home') : next()
    },
    {
        path: '/account/resetPassword',
        component: () => import(/* webpackChunkName: "group-account" */ '@/views/account/ResetPassword.vue')
    },
    {
        path: '/ua/account/resetPassword',
        component: () => import(/* webpackChunkName: "group-account" */ '@/views/account/ResetPassword.vue'),
        props: {tenant: Tenant.UA},
    },
    {
        path: '/account/plans',
        component: () => import(/* webpackChunkName: "group-account" */ '@/views/profile/Plans.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/profiles/:profileId',
        component: () => import(/* webpackChunkName: "group-account" */ '@/views/profile/ProfilePreview.vue'),
        props: true,
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/how-to-become-master',
        component: () => import(/* webpackChunkName: "group-main-views" */ '@/views/become-master/HowToBecomeMaster.vue'),
    },
    {
        path: '/no-access',
        component: () => import(/* webpackChunkName: "group-main-views" */ '@/views/NoAccess.vue'),
    },
    {
        path: '/Level-of-mastery',
        component: () => import(/* webpackChunkName: "group-account" */ '@/views/profile/LevelOfMastery.vue'),
        props: true
    }
];

const onboardingRoutesEntries: ReadonlyArray<[OnboardingStepCode, RouteLocationRaw]> = [
    [OnboardingStepCode.WELCOME_SCREEN, {path: '/account/welcome', query: {}}],
    [OnboardingStepCode.FILL_PERSONAL_INFO, {path: '/tabs/profile', query: {"activePanel": "profile"}}],
    [OnboardingStepCode.SETUP_AVAILABILITY, {path: '/tabs/profile', query: {"activePanel": "availability"}}],
    [OnboardingStepCode.MASTER_CONSULTATIONS, {path: '/how-to-become-master', query: {}}],
    [OnboardingStepCode.CREATE_FIRST_INQUIRY, {path: '/inquiries/create', query: {}}],
];
const onboardingRoutes = new Map<OnboardingStepCode, RouteLocationRaw>(onboardingRoutesEntries);
const organizationsOnboardingRoutes = new Map<OnboardingStepCode, RouteLocationRaw>(onboardingRoutesEntries.slice(0, 2))

let router: Router | null = null;

function getRouter() {
    if (router) {
        return router;
    }
    router = createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes
    });

    async function getUserProfile() {
        const userProfile = store.getters.userProfile;
        if (Auth.currentUser && !userProfile.id) {
            return await getCurrentProfile() || userProfile;
        }
        return userProfile;
    }

    router.beforeEach(async (to, from, next) => {
        const goNext = async () => {
            if (store.state.referralCode && !to.query.referralCode) {
                to.query.referralCode = store.state.referralCode;
                next({path: to.path, query: to.query});
                return;
            }
            if (!!to.query.notification) {
                setTimeout(() => store.commit(MUTATIONS.SET_PUSH_NOTIFICATION, to.query.notification), 1000);
            }
            const userProfile = await getUserProfile();
            const nextOnboardingStep = userProfile?.id && userProfile.additionalInfo?.getNextOnboardingStep();
            if (nextOnboardingStep) {
                const routes = userProfile.isOrganization ? organizationsOnboardingRoutes : onboardingRoutes;
                const nextOnboardingRoute = nextOnboardingStep && routes.get(nextOnboardingStep);
                if (nextOnboardingRoute && (to.path !== nextOnboardingRoute.path || !isEqual(to.query, nextOnboardingRoute.query))) {
                    console.log("next route", nextOnboardingStep);
                    setNotificationIfNeeded();
                }
            }
            next();
        };

        if (!to.path.includes('/account/login')) {
            if (to.meta.authRequired && !Auth.currentUser) {
                const prefix = store.state.features.ua ? '/ua' : '';
                const loginPagePath = prefix + '/account/login';
                next({path: loginPagePath, query: {go: to.fullPath}});
            }
            if (to.meta.authCheck) {
                const checkFn = to.meta.authCheck as AuthCheck;
                if (!(await checkFn(to.params))) {
                    window.location.replace('/no-access');
                }
            }
        }
        return await goNext();
    });

    router.afterEach((to, from) => {
        if (to.query.referralCode) {
            store.commit(MUTATIONS.SET_REFERRAL_CODE, to.query.referralCode);
        }
        try {
            const locationParts = to.fullPath.split("?");
            const query = locationParts?.length > 1 && locationParts.pop();
            logEvent(Analytics, 'screen_view', {
                firebase_screen: to.path + (query ? '?' + query : ''),
                firebase_screen_class: "Router"
            })
        } catch (e) {
            console.warn("Could not send GA event", e);
        }
    });
    return router;
}

export {
    getRouter
};
