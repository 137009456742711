/*
 * Actions
 * Perform async tasks, then mutate state
 */
import {ActionTree, GetterTree} from "vuex";
import {State} from "@/store/index";
import {MUTATIONS} from "@/store/mutations";
import {promptPwaInstall, showLoader, toast} from "@/common/alerts";
import {alertController, isPlatform} from "@ionic/vue";
import {
    createUserWithEmailAndPassword,
    getAdditionalUserInfo,
    linkWithPopup,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    unlink,
    User
} from 'firebase/auth';
import {Analytics, Auth} from "@/firebase/credentials";
import {
    deleteProfile,
    getCurrentProfile,
    observeMastershipChanges,
    observeProfileChanges,
    registerForPushNotifications
} from "@/common/userService";
import {getRouter} from "@/router";
import {
    createGoogleAuthProvider,
    createLinkedinProvider,
    getProviderForProviderId,
    handleAuthError,
    LoginCredentials,
    loginRedirect
} from "@/auth/authService";
import {observeSubscriptionChanges} from "@/common/subscriptionService";
import {logEvent, setUserId} from "firebase/analytics";
import firebase from "firebase/compat";
import {observeUserNotifications} from "@/common/matchingService";
import {RouteLocation} from "vue-router";
import {i18n, setLocale} from "@/i18n";
import {observeEvents, observeEventsFilters} from "@/common/eventService";
import {ProfileStatus} from "mastermatch-shared";
import Unsubscribe = firebase.Unsubscribe;


const router = getRouter();
let unsubscribeProfileChanges: Unsubscribe | undefined;
let unsubscribeMastershipChanges: Unsubscribe | undefined;
let unsubscribeAuthStateChanges: Unsubscribe | undefined;
let unsubscribeNotifications: Unsubscribe | undefined;
let unsubscribeEvents: Unsubscribe | undefined;
let unsubscribeEventsFilters: Unsubscribe | undefined;

// getters
const getters: GetterTree<State, any> = {
    ['authUser'](state) {
        return state.authUser;
    },
    ['authenticated'](state) {
        return state.authUser.isAuthenticated();
    },
    ['userProfile'](state) {
        return state.userProfile;
    },
    ['mastership'](state) {
        return state.mastership;
    },
    ['subscriptionInfo'](state) {
        return state.subscriptionInfo;
    },
    ['featureFlags'](state) {
        return state.features;
    },
    ['referralCode'](state) {
        return state.features;
    },
    ['inquiryNotifications'](state) {
        return state.inquiryNotifications;
    },
    ['businessEvents'](state) {
        return state.businessEvents;
    },
    ['locale'](state) {
        return state.settings.languageCode;
    },
    ['notification'](state) {
        return state.pushNotification;
    }
};

export const enum ACTIONS {
    OBSERVE_AUTH_STATE_CHANGED = 'OBSERVE_AUTH_STATE_CHANGED',
    UNSUBSCRIBE_AUTH_STATE_CHANGED = 'UNSUBSCRIBE_AUTH_STATE_CHANGED',
    OBSERVE_PROFILE_CHANGES = 'OBSERVE_PROFILE_CHANGES',
    UNSUBSCRIBE_PROFILE_CHANGES = 'UNSUBSCRIBE_PROFILE_CHANGES',
    OBSERVE_NOTIFICATIONS = 'OBSERVE_NOTIFICATIONS',
    UNSUBSCRIBE_NOTIFICATIONS = 'UNSUBSCRIBE_NOTIFICATIONS',
    OBSERVE_EVENTS = 'OBSERVE_EVENTS',
    UNSUBSCRIBE_EVENTS = 'UNSUBSCRIBE_EVENTS',
    RETRIEVE_SUBSCRIPTION = 'RETRIEVE_PLAN_INFO',
    FORCE_SIGN_IN = 'FORCE_SIGN_IN',
    REGISTER_USER = 'REGISTER_USER',
    RESET_PASSWORD = 'RESET_PASSWORD',
    LOGIN_GOOGLE = 'LOGIN_GOOGLE',
    LOGIN_LINKEDIN = 'LOGIN_LINKEDIN',
    LINK_WITH_PROVIDER = 'LINK_WITH_PROVIDER',
    SIGN_IN = 'SIGN_IN',
    SIGN_OUT = 'SIGN_OUT',
    REGISTER_PUSH_NOTIFICATIONS = 'REGISTER_PUSH_NOTIFICATIONS',
    PROMPT_PWA_INSTALL = 'PROMPT_PWA_INSTALL',
    DELETE_ACCOUNT = 'DELETE_ACCOUNT',
    SET_LOCALE = 'SET_LOCALE',
}

const actions: ActionTree<State, any> = {
    [ACTIONS.OBSERVE_AUTH_STATE_CHANGED](store) {
        unsubscribeAuthStateChanges = Auth.onAuthStateChanged(async user => {
            if (user) {
                await store.commit(MUTATIONS.SET_USER, user);
                await store.dispatch(ACTIONS.OBSERVE_PROFILE_CHANGES);
                await store.dispatch(ACTIONS.OBSERVE_NOTIFICATIONS);
                await store.dispatch(ACTIONS.OBSERVE_EVENTS);
                await store.dispatch(ACTIONS.SET_LOCALE);
                await store.dispatch(ACTIONS.REGISTER_PUSH_NOTIFICATIONS);
                await store.dispatch(ACTIONS.RETRIEVE_SUBSCRIPTION);
                store.dispatch(ACTIONS.PROMPT_PWA_INSTALL);
            } else {
                await store.dispatch(ACTIONS.UNSUBSCRIBE_PROFILE_CHANGES);
                await store.dispatch(ACTIONS.UNSUBSCRIBE_NOTIFICATIONS);
                await store.dispatch(ACTIONS.UNSUBSCRIBE_EVENTS);
                await store.commit(MUTATIONS.UNSET_USER);
            }
        });
        Auth.onIdTokenChanged((user: User | null) => {
            if (user) {
                store.commit(MUTATIONS.SET_USER, user);
            }
        });
    },

    [ACTIONS.UNSUBSCRIBE_AUTH_STATE_CHANGED](store) {
        if (unsubscribeProfileChanges) {
            unsubscribeProfileChanges();
        }
        if (unsubscribeAuthStateChanges) {
            unsubscribeAuthStateChanges();
        }
    },

    async [ACTIONS.OBSERVE_PROFILE_CHANGES](store) {
        const currentUserId = Auth.currentUser?.uid;
        const currentProfile = await getCurrentProfile(currentUserId);
        if (currentProfile?.id) {
            await store.commit(MUTATIONS.SET_USER_PROFILE, currentProfile);
            //GA user
            setUserId(Analytics, currentProfile.id);
        }
        unsubscribeMastershipChanges = await observeMastershipChanges(async mastership => {
            await store.commit(MUTATIONS.SET_MASTERSHIP, mastership);
        }, currentUserId);
        unsubscribeProfileChanges = await observeProfileChanges(async profile => {
            await store.commit(MUTATIONS.SET_USER_PROFILE, profile);
        });
    },

    [ACTIONS.UNSUBSCRIBE_PROFILE_CHANGES]() {
        if (unsubscribeProfileChanges) {
            unsubscribeProfileChanges();
        }
        if (unsubscribeMastershipChanges) {
            unsubscribeMastershipChanges();
        }
    },

    [ACTIONS.OBSERVE_NOTIFICATIONS](store) {
        unsubscribeNotifications = observeUserNotifications((notifications) => store.state.inquiryNotifications = notifications);
    },

    async [ACTIONS.UNSUBSCRIBE_NOTIFICATIONS](store) {
        if (unsubscribeNotifications) {
            unsubscribeNotifications();
        }
    },

    async [ACTIONS.OBSERVE_EVENTS](store) {
        unsubscribeEvents = await observeEvents((events) => store.state.businessEvents = events);
        unsubscribeEventsFilters = await observeEventsFilters((events) => store.state.businessEvents = events);
    },

    [ACTIONS.UNSUBSCRIBE_EVENTS](store) {
        if (unsubscribeEvents) {
            unsubscribeEvents();
        }
        if (unsubscribeEventsFilters) {
            unsubscribeEventsFilters();
        }
    },

    async [ACTIONS.FORCE_SIGN_IN](store, to: RouteLocation) {
        toast({header: i18n.global.t('toast.auth.mustLogin'), position: "top"});
        const prefix = store.state.features.ua ? '/ua' : '';
        window.location.replace(prefix + '/account/login');
    },
    async [ACTIONS.REGISTER_USER](store, options: { loginCredentials: LoginCredentials, route: '/account/welcome'; }) {
        const loader = await showLoader();
        createUserWithEmailAndPassword(Auth, options.loginCredentials.email, options.loginCredentials.password)
            .then((userCredential) => {
                store.commit(MUTATIONS.SET_USER, userCredential.user);
                router.push(options.route);
                //GA user
                setUserId(Analytics, userCredential.user.uid);
                logEvent(Analytics, 'sign_up', {method: 'email'});
            }).catch(handleAuthError)
            .finally(() => loader.dismiss());
    },
    async [ACTIONS.RESET_PASSWORD](store, options: { email: string, route: '/account/login'; }) {
        logEvent(Analytics, 'reset_password');
        sendPasswordResetEmail(Auth, options.email)
            .then(() => {
                toast({header: i18n.global.t('toast.auth.resetLinkConfirmation.header'), duration: 4000});
                router.replace(options.route);
            })
            .catch((error: any) => {
                console.log(error);
                switch (error.code) {
                    case 'auth/user-not-found':
                        toast({
                            header: i18n.global.t('toast.auth.userNotFound.header'),
                            message: i18n.global.t('toast.auth.userNotFound.message'),
                            color: 'warning',
                            duration: 3000
                        });
                        break;
                    default:
                        toast({header: error.message, color: 'danger'});
                }
            });
    },

    async [ACTIONS.LOGIN_GOOGLE](store, route = "/") {
        const loader = await showLoader();
        const provider = createGoogleAuthProvider();
        signInWithPopup(Auth, provider).then(async (userCredential) => {
            const userInfo = await getAdditionalUserInfo(userCredential);
            setUserId(Analytics, userCredential.user.uid);
            logEvent(Analytics, 'login', {method: 'Google', tenant: store.state.features.ua ? 'ua' : 'default'});
            await loginRedirect(userInfo?.isNewUser ? '/account/welcome' : route);
        }).catch(handleAuthError)
            .finally(() => loader.dismiss());
    },

    [ACTIONS.LOGIN_LINKEDIN](store, route = "/") {
        const provider = createLinkedinProvider();
        signInWithPopup(Auth, provider)
            .then(async userCredential => {
                const userInfo = await getAdditionalUserInfo(userCredential);
                setUserId(Analytics, userCredential.user.uid);
                logEvent(Analytics, 'login', {method: 'Linkedin', tenant: store.state.features.ua ? 'ua' : 'default'});
                await loginRedirect(userInfo?.isNewUser ? '/account/welcome' : route);
            })
            .catch(handleAuthError);
    },

    [ACTIONS.LINK_WITH_PROVIDER](store, providerId) {
        const user = Auth.currentUser;
        if (user) {
            const provider = getProviderForProviderId(providerId);
            const userProfile = store.state.authUser;
            if (userProfile.isLinkedWithProvider(providerId)) {
                unlink(user, providerId).then((result) => {
                    toast({header: i18n.global.t('toast.auth.unlinkAccountsSuccess.header'), color: "success"});
                    userProfile.unlinkProvider(providerId);
                    logEvent(Analytics, 'link_with_provider', {providerId});
                }).catch(handleAuthError);
            } else {
                linkWithPopup(user, provider).then((result) => {
                    toast({header: i18n.global.t('toast.auth.linkAccountsSuccess.header'), color: "success"});
                }).catch(handleAuthError);
            }
        }
    },

    async [ACTIONS.SIGN_IN](store, params: { credentials: LoginCredentials; route: string; }) {
        const loader = await showLoader();
        signInWithEmailAndPassword(Auth, params.credentials.email, params.credentials.password)
            .then(async (userCredential) => {
                store.commit(MUTATIONS.SET_USER, userCredential.user);
                setUserId(Analytics, userCredential.user.uid);
                logEvent(Analytics, 'sign_up', {method: 'email', tenant: store.state.features.ua ? 'ua' : 'default'});
                await loginRedirect(params.route);
            })
            .catch(handleAuthError)
            .finally(() => loader.dismiss());
    },
    [ACTIONS.SIGN_OUT](store) {
        Auth.signOut()
            .then(async (userCredential) => {
                await store.commit(MUTATIONS.UNSET_USER);
                toast({header: i18n.global.t('toast.auth.signOut.header')});
                const prefix = store.state.features.ua ? '/ua' : '';
                // window.location.replace(prefix + '/account/login');
                router.replace(prefix + '/account/login');
                logEvent(Analytics, 'sign_out');
            }).catch(handleAuthError);
    },
    async [ACTIONS.DELETE_ACCOUNT](store) {
        async function deleteAccount() {
            const user = Auth.currentUser;
            if (user) {
                const loader = await showLoader();
                await deleteProfile(user.uid);
                user.delete()
                    .then(async (userCredential) => {
                        await store.commit(MUTATIONS.UNSET_USER);
                        const duration = 3000;
                        await toast({
                            header: i18n.global.t('toast.auth.accountDeleted.header'),
                            message: i18n.global.t('toast.auth.accountDeleted.message'),
                            duration
                        });
                        setTimeout(() => window.location.replace('/account/login'), duration);

                        logEvent(Analytics, 'delete_account');
                    })
                    .catch(handleAuthError)
                    .finally(() => loader.dismiss());
            }
        }

        const alert = await alertController.create({
            header: i18n.global.t("alert.account.delete.header"),
            message: i18n.global.t("alert.account.delete.message"),
            buttons: [
                {
                    text: i18n.global.t("alert.account.delete.cancelBtn"),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: blah => {
                        console.log('Confirm Cancel:', blah);
                    },
                },
                {
                    text: i18n.global.t("alert.account.delete.confirmBtn"),
                    handler: deleteAccount
                },
            ],
        });
        return alert.present();
    },

    async [ACTIONS.REGISTER_PUSH_NOTIFICATIONS](store) {
        const {NODE_ENV = ''} = process.env;
        if (['production', 'prod', 'test'].includes(NODE_ENV)) {
            console.debug("Registering service worker");
            await registerForPushNotifications(store.state, (token) => store.commit(MUTATIONS.SET_FCM_TOKEN, token));
        }
    },

    async [ACTIONS.PROMPT_PWA_INSTALL](store) {
        // Detects if device is on iOS
        const isIos = () => {
            const userAgent = window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test(userAgent);
        };
        // Detects if device is in standalone mode
        const nav: any = window.navigator;

        const isInStandaloneMode = () => isPlatform('capacitor') || (('standalone' in nav) && (nav.standalone));
        // Checks if should display install popup notification:
        if (!store.state.settings.pwaAcknowledged && isIos() && !isInStandaloneMode()) {
            const pwaToast = await promptPwaInstall();
            const {role} = await pwaToast.onDidDismiss();
            console.log('onDidDismiss resolved with role', role);
            if (role == 'acknowledged') {
                await store.commit(MUTATIONS.PWA_INSTALL_ACK);
            }
        }
    },

    async [ACTIONS.RETRIEVE_SUBSCRIPTION](store) {
        const currentUser = store.state.userProfile;
        if (store.state.features.subscriptions && currentUser?.isOrganization && currentUser.status !== ProfileStatus.NEW) {
            observeSubscriptionChanges(currentUser.id as string);
        }
    },

    async [ACTIONS.SET_LOCALE](store, languageCode?: string) {
        const userLanguage = store.state.userProfile?.accountSettings.appLanguageCode;
        const browserSavedLocale = store.state.settings.languageCode;
        const locale = languageCode || userLanguage || browserSavedLocale || navigator.language.split('-')[0];
        const currentLocale = setLocale(locale);
        store.commit(MUTATIONS.SAVE_LANGUAGE, currentLocale);
    }
};

export {
    actions,
    getters
};