import { i18n } from "@/i18n";
import { alertController, loadingController, toastController, ToastOptions } from "@ionic/vue";
import { informationCircle } from 'ionicons/icons';
import {TranslateResult} from "vue-i18n";

const toastDefaultOptions = {
    color: "primary",
    position: 'middle',
    icon: informationCircle
};

const toast = async (options: ToastOptions, dismissable = false) => {
    const effectiveOptions = Object.assign({}, toastDefaultOptions, options);
    if (dismissable) {
        effectiveOptions.buttons ||= [];
        effectiveOptions.buttons.push({
            text: 'Ok',
            role: 'ok'
        });
    } else {
        effectiveOptions.duration = 3000;
    }
    const toast = await toastController.create(effectiveOptions);
    await toast.present();
    return toast;

};

const showLoader = async (message?: string): Promise<HTMLIonLoadingElement> => {
    const loading = await loadingController.create({ message });
    await loading.present();
    return loading;
};

const promptPwaInstall = async () => {
    const toast = await toastController
        .create({
            header: i18n.global.t('toast.pwaInstall.header'),
            message: i18n.global.t('toast.pwaInstall.message'),
            position: 'bottom',
            buttons: [
                {
                    side: 'end',
                    text: 'Got it',
                    role: "acknowledged",
                    handler: () => {
                        console.debug('Got it clicked');
                    }
                }
            ]
        });
    await toast.present();
    return toast;
};

const presentConfirm = async function (header: string,
                                       message: string,
                                       continueText = i18n.global.t('buttons.yes'),
                                       cancelText = i18n.global.t('buttons.no')) {
    return new Promise((resolve) => {
        alertController.create({
            header,
            message,
            cssClass: 'confirm-alert',
            buttons: [
                {
                    text: cancelText,
                    handler: () => resolve(false),
                },
                {
                    text: continueText,
                    handler: () => resolve(true)
                },
            ],
        }).then((alert) => alert.present());
    });
}

const promptCancelMeetingReason = async function () {
    return new Promise(async (resolve) => {
        const alert = await alertController.create({
            header: i18n.global.t("toast.presentConfirm.cancelMeeting.header") as TranslateResult,
            message: i18n.global.t("toast.presentConfirm.cancelMeeting.message"),
            cssClass: "confirm-alert",
            inputs: [{placeholder: i18n.global.t("placeholder.cancelReason")}],
            buttons: [
                {
                    text: i18n.global.t("buttons.no"),
                    role: "cancel",
                    handler: () => resolve(false),
                },
                {
                    text: i18n.global.t("buttons.yes"),
                    handler: (value) => {
                        if (!!value[0]) {
                            resolve(value[0])
                        } else {
                            toast({
                                    header: i18n.global.t("toast.presentConfirm.cancelMeeting.validationHeader"),
                                    message: i18n.global.t("toast.presentConfirm.cancelMeeting.validationMessage"),
                                    color: "danger",
                                    position: 'middle'
                                },
                                true);
                            alert.present()
                        }
                    },
                }
            ],
        });
        await alert.present();
    });
}

export {
    promptCancelMeetingReason,
    presentConfirm,
    toast,
    showLoader,
    promptPwaInstall
};